'use strict';

function convertToDataURLviaCanvas(url, callback, outputFormat){
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null; 
    };
    img.src = url;
}


angular.module('content')
.controller('contentTabCtrl',
function($scope, projectService, contentTabsService) {
    "ngInject";

    $scope.project = projectService.current.project;
    $scope.schemas = projectService.current.project.schemas;

    $scope.schemaName = function(schema) {
        return schema.data.name || schema.data.valueUri;
    };

    $scope.selectSchema = contentTabsService.selectSchema;
    $scope.selectedIndex = contentTabsService.activeIndex;

})

angular.module('content')
.controller('contentCtrl',
function (contentStorageService, contentTabsService, $state, $scope, $q, $mdDialog, projectService, facebookService, SROService, $timeout, $window, $http, dashboardConfigService) {
    
    "ngInject";

    $scope.activeSchema = undefined;
    $scope.detailsForm = {};

    // AB: I really like this as a way to bind values from servies to $scope
    Object.defineProperty($scope, 'activeIndex', {
        get: function() { 
            return contentTabsService.activeIndex; 
        },
        set: function(val) { contentTabsService.activeIndex = val; },
    });

    function setDetailsFormPristine() {
        if ($scope.detailsForm.form)
            $scope.detailsForm.form.$setPristine();
        for (var el in $scope.activeSchema.data.schema) {
            if ($scope.activeSchema.data.schema[el].formControl)
                $scope.activeSchema.data.schema[el].formControl.$setPristine();
        }
    }

    $scope.tabSelected = function tabSelected(){
        console.log("hello");
    }

    // get products for this project
    $scope.products = {};
    firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/products/").on('value', function(res){
        let products = res.val();
        console.log(products);
        firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/skus/").on('value', function(res){
            let skus = res.val();
            console.log(skus);
            for (let skuId in skus) {
                let sku = skus[skuId];
                sku.id = skuId;
                if (products[sku.product]) {
                    $scope.products[skuId] = {"product":products[sku.product],"sku":sku};
                }
            }
            console.log($scope.products);
        });
    });

    $scope.cart = {total:0,items:[]};

    $scope.addToCart = function(skuId,quantity) {
        console.log($scope.products[skuId]);
        $scope.cart.total += $scope.products[skuId].sku.price * quantity;
        let item = {
            sku: skuId,
            price: $scope.products[skuId].sku.price,
            quantity: quantity,
            lineDescription: quantity + " x " + $scope.products[skuId].product.name + " @ $" + ($scope.products[skuId].sku.price/100)
        }
        $scope.cart.items.push(item);
        console.log($scope.cart);
    }

    $scope.removeFromCart = function(sku,quantity) {
        $scope.cart.total -= scope.products[skuId].sku.price * quantity;
    }

    $scope.clearCart = function() {
        $scope.cart = {total:0,items:[]};
    }

    $scope.goToCheckout = function() {
        console.log($scope.smsSelection);

        $scope.clearCart();
        $scope.addToCart($scope.smsSelection.product,$scope.smsSelection.quantity);

        function DialogController($scope, $mdDialog, cart) {
            console.log(cart);

            $scope.cart = cart;
            $scope.disabled = false;

            $scope.hide = function() {
              $mdDialog.hide();
            };

            $scope.cancel = function() {
              $mdDialog.cancel();
            };

            $scope.purchase = function() {

              $scope.disabled = true;

              console.log(firebase.auth().currentUser);
              firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) =>  {

                  //let backendBaseURI = 'https://19l24h8r1d.execute-api.ap-southeast-2.amazonaws.com/dev/billing';
                  let backendBaseURI = 'https://3rzrmkam52.execute-api.ap-southeast-2.amazonaws.com/dev/billing';

                  let project = projectService.current.project.tag;
                  let action = "CREATE_CHARGE";
                  
                  let payload = {"action":action, "idToken":idToken, "project":project, "cart":$scope.cart};
                  console.log(payload);
                  
                  $http.post(backendBaseURI, payload).then(function(result) {
                      console.log(result);
                      $scope.disabled = false;
                      var alert = $mdDialog.alert({
                        title: 'Thank you!',
                        textContent: 'An invoice will be emailed to your billing email address shortly.',
                        ok: 'Ok'
                        });

                    $mdDialog
                        .show( alert )
                        .finally(function() {
                            $mdDialog.hide();
                        });


                      
                  }, function(result) {
                      $scope.disabled = false;
                      var alert = $mdDialog.alert({
                        title: 'Oops',
                        textContent: 'Something went wrong with our payment processing. Please try again.',
                        ok: 'Ok'
                        });

                    $mdDialog
                        .show( alert )
                        .finally(function() {
                            $mdDialog.hide();
                        });
                  });
              });
          }
        }


        $mdDialog.show({
          controller: DialogController,
          template: '\
            <md-dialog aria-label="Checkout">\
              <form ng-cloak>\
                <md-toolbar>\
                  <div class="md-toolbar-tools">\
                    <h2>Checkout</h2>\
                    <span flex></span>\
                  </div>\
                </md-toolbar>\
                <md-dialog-content>\
                  <div class="md-dialog-content">\
                  <div ng-repeat="item in cart.items">\
                  {{item.lineDescription}}\
                  </div>\
                  <div>\
                  <h1>Total: ${{cart.total/100}}</h1>\
                  </div>\
                </md-dialog-content>\
                <md-dialog-actions layout="row">\
                  <md-button ng-disabled="disabled" ng-click="cancel()">\
                   Cancel\
                  </md-button>\
                  <md-button ng-disabled="disabled" ng-click="purchase()">\
                   Purchase\
                  </md-button>\
                </md-dialog-actions>\
              </form>\
            </md-dialog>\
          ',
          parent: angular.element(document.body),
          locals: {cart:$scope.cart},
          //targetEvent: ev,
          clickOutsideToClose:true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(function(answer) {
          $scope.status = 'You said the information was "' + answer + '".';
        }, function() {
          $scope.status = 'You cancelled the dialog.';
        });

    }


    $scope.$watch('activeIndex', function(newValue) {
        if (typeof newValue === "undefined" || newValue < 0 || newValue == $scope.schemas.length) {
            return;
        }

        $scope.activeSchema = $scope.schemas[newValue];
        
        if ($scope.activeSchema.data.displayType==="detail")
        {
            contentStorageService.getValue($scope.activeSchema.data.valueUri)
            .then(function(result) {
                $scope.schemaValue = result;
                setDetailsFormPristine();

            });
        }
        else if ($scope.activeSchema.data.displayType==="sms") {
            $scope.smsRemainingCredits = 0;
            $scope.smsSelection = {product:"sku_BUJoyWYMmBGAFW", quantity:"1000"};

            firebase.database().ref("projectData/" + projectService.current.project.tag + "/adminReadOnly/sms/remainingCredits").on('value', function(res){
                $scope.smsRemainingCredits = res.val() || 0;
            });
        }
        else if ($scope.activeSchema.data.displayType==="sms2") {
            $scope.smsRemainingCredits = 0;
            $scope.smsSelection = {product:"sku_BUJoyWYMmBGAFW", quantity:"1000"};

            firebase.database().ref("projectData/" + projectService.current.project.tag + "/adminReadOnly/sms/remainingCredits").on('value', function(res){
                $scope.smsRemainingCredits = res.val() || 0;
            });
        }        
        else if ($scope.activeSchema.data.displayType==="Facebook")
        {
            
        }
        else if ($scope.activeSchema.data.displayType==="promengin_billing") {
            
            $scope.stripeData = {customer:'INIT',email:null,busy:false};

            // Configure stripe settings
            $scope.dashboardConfig = dashboardConfigService.getConfig();
            var backendBaseURI = "https://a0llv8oee7.execute-api.ap-southeast-2.amazonaws.com/dev/promenginbilling";
            if ('stripeBillingEndpoint' in $scope.dashboardConfig)
                backendBaseURI = $scope.dashboardConfig.stripeBillingEndpoint;
            var sripeKey = 'pk_live_vicOGWPgEsSLRUzhnQfRFcL7';
            if ('stripePublicKey' in $scope.dashboardConfig)
                sripeKey = $scope.dashboardConfig.stripePublicKey;
            
            $scope.stripe = $window.Stripe(sripeKey);

            $scope.stripeTokenHandler = function(token) {

                console.log(token);
                
                var value = {"project":projectService.current.project.tag, "action":"UPDATE_CREDIT_CARD_DETAILS", "token":token.id, "email":token.email};
                $http.post(backendBaseURI, value).then(function(result) {
                    console.log('credit card update success');
                    console.log(result);
                    $scope.stripeData.busy = false;
                }, function(error) {
                    console.log('credit card update error');
                    console.log(error);
                    $scope.stripeData.busy = false;
                });
            }

            $scope.deleteCard = function() {
                $scope.stripeData.busy = true;
                var value = {"project":projectService.current.project.tag, "action":"DELETE_CREDIT_CARD_DETAILS"};
                $http.post(backendBaseURI, value).then(function(result) {
                    console.log('credit card delete success');
                    console.log(result);
                    $scope.stripeData.customer = null;
                    $scope.stripeData.busy = false;
                }, function(error) {
                    console.log('credit card delete error');
                    console.log(error);
                    $scope.stripeData.busy = false;
                });
            } 

            $scope.pay = function() {
                $scope.stripeData.busy = true;

                var additionalData = {
                  email: $scope.stripeData.stripeEmail
                };

                $scope.stripe.createToken($scope.card, additionalData).then(function(result) {
                    if (result.error) {
                      // Inform the customer that there was an error
                      var errorElement = document.getElementById('card-errors');
                      errorElement.textContent = result.error.message;
                      $scope.stripeData.busy = false;
                    } else {
                      // Send the token to your server
                      $scope.stripeTokenHandler(result.token);
                    }
                });
            }

            // Custom styling can be passed to options when creating an Element.
            var style = {
              base: {
                color: '#32325d',
                lineHeight: '18px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: 'rgba(0,0,0,0.38)'
                }
              },
              invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
              }
            };
            // Create an instance of the card Element
            $scope.card = $scope.stripe.elements().create('card', {style: style});

            $timeout(function()
            {
                // Add an instance of the card Element into the `card-element` <div>
                $scope.card.mount('#card-element');
            });
            
            function customerUpdate(result) {
                if (result.val()) {
                    $scope.stripeData['customer'] = result.val()[Object.keys(result.val())[0]];
                    console.log($scope.stripeData.customer);
                }    
                else {
                    $scope.stripeData['customer'] = null;
                }
                $timeout(function()
                {
                    $scope.$apply();
                });
            }

            var baseUri = "projectData/" + projectService.current.project.tag;
            firebase.database().ref(baseUri + "/admin/billing/customer").on('value', customerUpdate);

        }
        else if ($scope.activeSchema.data.displayType==="promengin_reports")
        {
            var dateObj = new Date();
            var month = dateObj.getUTCMonth() + 1; //months from 1-12
            var year = dateObj.getUTCFullYear();
            $scope.reportRange = {'fromYear':year, 'fromMonth':month, 'toYear':year, 'toMonth':month};
            $scope.surveySentReport = [];
            $scope.responseReceivedReport = [];

            $scope.exportAsCSV = function(isResponses) {
                var element = document.createElement('a');
                var content = encodeURIComponent($scope.responseReceivedReport);
                var filename = "responses.csv";
                if (!isResponses) {
                    content = encodeURIComponent($scope.surveySentReport);
                    filename = "surveys.csv";
                }
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + content);
                element.setAttribute('download', filename);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }

            $scope.printReport = function(report) {
                $scope.surveySentReport = [];
                $scope.responseReceivedReport = [];
                var baseUri = "projectData/" + projectService.current.project.tag;
                var ref = firebase.database().ref(baseUri + "/admin/permanent/surveys/"+$scope.reportRange.fromYear+"/"+$scope.reportRange.fromMonth);
                ref.once('value', function(result) {
                    var surveys = result.val();
                    for (var surveyId in surveys) {
                        var survey = surveys[surveyId];
                        for (var participantId in survey.events) {
                            var location = "N/A";
                            if ("location" in survey.participants[participantId]) {
                                location = survey.participants[participantId]["location"];
                            }
                            var eventsForParticipant = survey.events[participantId];
                            for (var eventId in eventsForParticipant) {
                                var event = eventsForParticipant[eventId];
                                if (event.event === "SURVEY_SENT") {
                                    $scope.surveySentReport.push(
                                        {
                                            date:event.date, 
                                            location:location,
                                            participantId:participantId,
                                            surveyType:survey.type,
                                            surveyName:survey.name,
                                            deliveryMethod:"Email"
                                        }
                                    );
                                }
                                else if (event.event === "RESPONSE_RECEIVED") {
                                    var row = {
                                            date:event.date, 
                                            location:location,
                                            participantId:participantId,
                                            surveyType:survey.type,
                                            surveyName:survey.name,
                                            deliveryMethod:"Email"
                                    }
                                    for (var responseId in event.responseData) {
                                        var response = event.responseData[responseId];
                                        row[response.name] = response.answer;
                                    }
                                    $scope.responseReceivedReport.push(row);
                                }
                            }
                        }
                    }
                    $scope.surveySentReport = window.Papa.unparse($scope.surveySentReport);
                    $scope.responseReceivedReport = window.Papa.unparse($scope.responseReceivedReport);
                });
            }
            $scope.printReport();

            $scope.onReportRangeChanged = function() {
                $scope.printReport();
            }
        }
        else
        {
            contentStorageService.getValues($scope.activeSchema.data.valueUri)
            .then(function(result) {
                $scope.schemaValue = result;
                setDetailsFormPristine();

            });
        }

        refreshValue();
    });

    $scope.schemas = projectService.current.project.schemas;

    $scope.schemaValue = {};

    function refreshValue() {
        if ($scope.activeSchema.data.displayType==="detail")
        {
            contentStorageService.getValue($scope.activeSchema.data.valueUri)
            .then(function(result) {
                $scope.schemaValue = result;
                setDetailsFormPristine();
            });            
        }
        else
        {
            contentStorageService.getValues($scope.activeSchema.data.valueUri)
            .then(function(result) {
                $scope.schemaValue = result;
                setDetailsFormPristine();
            });
        }
    }

    $scope.schemaName = function(schema) {
            return schema.data.name || schema.data.valueUri;
    }


    // for a given value, return the summary for display in a list/title
    $scope.valueSummary = function(value) {
        var summary = undefined;

        if ($scope.activeSchema.data.summary && value.data) {
            summary = value.data[$scope.activeSchema.data.summary];
            // this handles dot notation i.e. if the summary in the schema is something like
            // uploadFile.name
            if (summary === undefined && value.data) {
                function index(obj,i) {return obj[i]}
                summary = ($scope.activeSchema.data.summary).split('.').reduce(index, value.data)
            }
        }
        
        return summary ? summary : "---";
    }

    $scope.editValue = function(uri) {
        // uri == undefined implies creation of new value
        if ($scope.activeSchema.data.name === "Email (2.0)") {
            $state.go('content.email2', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Email Templates") {
            $state.go('content.email', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Email Messages (beta)") {
            $state.go('content.email', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Campaigns (beta)") {
            $state.go('content.detail', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Member Groups") {
             $state.go('content.groups', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Push Messages") {
             $state.go('content.push', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Push (2.0)") {
            $state.go('content.push2', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Surveys") {
             $state.go('content.surveys', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Vouchers") {
             $state.go('content.vouchers', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "SMS") {
             $state.go('content.sms', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "SMS (2.0)") {
             $state.go('content.sms2', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Qantum Vouchers" || $scope.activeSchema.data.name === "Special Offers") {
             $state.go('content.qVouchers', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
        else if ($scope.activeSchema.data.name === "Menu Groups") {
             $state.go('content.menuGroups', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }  
        else {
            $state.go('content.detail', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
        }
    }

    $scope.editValueFromFb = function(fbEvent) {
        var d = new Date(fbEvent.start_time);

        var initData = {tag: fbEvent.name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'_'),  
                        name:fbEvent.name, 
                        shortName:fbEvent.name,
                        longName:fbEvent.name,
                        description:fbEvent.description, 
                        title:fbEvent.name,
                        imageURL:fbEvent.cover.source,
                        resource:fbEvent.cover.source,
                        date:d
                        };
        $state.go('content.detail', {schemaIndex: 0, uri: undefined, initData:initData});
    }

    $scope.addFbEvent = function(fbEvent) {
        var uri = "projectData/" + projectService.current.project.tag + "/publicReadOnly/facebookEvents/" + fbEvent.id;
        var valueRef = firebase.database().ref(uri);
        var value = angular.toJson(fbEvent);
        valueRef.set(value).then(function() {
            fbEvent.added = true;
            $scope.$digest();
        });
    }

    $scope.removeFbEvent = function(fbEvent) {
        var uri = "projectData/" + projectService.current.project.tag + "/publicReadOnly/facebookEvents/" + fbEvent.id;
        var valueRef = firebase.database().ref(uri);
        valueRef.remove().then(function() {
            fbEvent.added = false;
            $scope.$digest();
        });
    }

    $scope.linkFacebook = function() {
        facebookService.link().then(function() {
            facebookService.initFacebookSDK().then(function() {
                $scope.getSelectedVenue();
            }); 
        });
    }

    $scope.isFacebookLinked = function() {
        return facebookService.getAccessToken()!=null;
    }

    $scope.editValueFromSRO = function(sroEvent) {

        var eventTimes = [];
        for (var idx in sroEvent.event_times)
        {
            time = new Date(sroEvent.event_times[idx].time);
            eventTimes.push({"eventTime":time});
        }

        var initData = {
             tag: sroEvent.short_name
                .toLowerCase()
                .replace(/[^\w ]+/g,'')
                .replace(/ +/g,'_'),            
             shortName:sroEvent.short_name,
             longName:sroEvent.short_name,
             externalLink:sroEvent.external_url,
             eventTimes:eventTimes,
             ticketInformation:sroEvent.pricing,
             venue:sroEvent.venue,
             id:sroEvent.id
        }

        var fbEvent = facebookService.get_from_name(initData.tag);
        if (fbEvent) {
            if ('cover' in fbEvent)
                initData['resource'] = fbEvent.cover.source;

            if ('description' in fbEvent)
                initData['description'] = fbEvent.description;
        }

        $state.go('content.detail', {schemaIndex: 0, uri: undefined, initData:initData});
    }

    $scope.deleteValue = function(value, schema) {
        contentStorageService.deleteValue(value, schema)
         .then(function() {
             //wizardAppService.removeAppShow(value.uri);
             refreshValue();
         });
    }

    $scope.archiveValue = function(oldUri,newUri) {
        contentStorageService.moveValue(oldUri, newUri)
         .then(function() {
             //wizardAppService.removeAppShow(value.uri);
             refreshValue();
         });        
    }

    $scope.confirmDelete = function(value) {

        var confirm = $mdDialog.confirm()
            .title('Confirm Delete')
            .textContent('Are you sure you\'d like to delete this item?')
            .ariaLabel('Secondary click demo')
            .ok('Delete')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            $scope.deleteValue(value, $scope.activeSchema.data.schema);
        }, function () {});

    };
 
    $scope.confirmArchive = function(value) {

        var confirm = $mdDialog.confirm()
            .title('Confirm Archive')
            .textContent('Are you sure you\'d like to move this item to the archive?')
            .ariaLabel('Secondary click demo')
            .ok('Archive')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            var key = value.uri.substr(value.uri.lastIndexOf('/')+1);
            $scope.archiveValue(value.uri, $scope.activeSchema.data.displayOptions.archiveLocation + "/" + key);
        }, function () {});

    };

    // if this is a multi venue project, we reveal a venue picker for
    // facebook tab
    var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/project/venues/");
    $scope.selectedVenue = {venue:{}};
    valueRef.once('value', function(result) { 
       $scope.venues = result.val();
       if ($scope.venues) {
            $scope.fbEventsLoading = true;
            $scope.selectedVenue.venue = $scope.venues[Object.keys($scope.venues)[0]];
            facebookService.initFacebookSDK().then(function() {

                facebookService.initFacebookEventData($scope.selectedVenue.venue.facebookUrl).then(function() {
                    $timeout(function()
                    {
                        $scope.fb_events = facebookService.fb_events();

                        $scope.fbEventsLoading = false;
                        $scope.$apply();
                    });
                },
                function(error) {
                    console.log(error);
                    $scope.fbEventsLoading = false;            
                }
                );
            });
       }
    });

    $scope.fbEventsLoading = true;
    $scope.fb_events = [];
    $scope.getSelectedVenue = function() {
        $scope.fbEventsLoading = true;
        console.log($scope.selectedVenue);
        facebookService.initFacebookSDK().then(function() {

            facebookService.initFacebookEventData($scope.selectedVenue.venue.facebookUrl).then(function() {
                $timeout(function()
                {
                    $scope.fb_events = facebookService.fb_events();

                    $scope.fbEventsLoading = false;
                    $scope.$apply();
                });
            },
            function(error) {
                console.log(error);
                $scope.fbEventsLoading = false;            
            }
            );
        });
    };

    facebookService.initFacebookSDK().then(function() {
            facebookService.initFacebookEventData(projectService.currentValue.facebook_url).then(function() {
                $scope.fb_events = facebookService.fb_events();
                $timeout(function()
                {
                    $scope.fbEventsLoading = false;
                    $scope.$apply();
                });
            },
            function(error) {
                console.log(error);
                $scope.fbEventsLoading = false;            
            });
    });   

    SROService.initData().then(function() {
        $scope.sroEvents = SROService.all();
    });

    $scope.save = function() {

        $timeout(function() {
            var promise = contentStorageService.saveValue($scope.schemaValue, null, $scope.activeSchema.data.schema);
            promise.then(function(result) {
                $scope.errorMsg = "None" 
            })
            .catch(function(result) {
                $scope.errorMsg = "Server error: " + result;
            })            
        }, 0);


    }

    $scope.connectDropbox = function() {
        localStorage.setItem('promenginRedirectState', 
            JSON.stringify({"name":"connectDropboxRequested", 'stateParams':{"project":projectService.current.project.tag}}));
        var dbx = new window.Dropbox({ clientId: '91r48w8ovz3vrc5' });
        var authUrl = dbx.getAuthenticationUrl('https://d1c6st74iy0pt4.cloudfront.net');
        console.log(authUrl)
        //document.getElementById('authlink').href = authUrl;
        $window.open(authUrl, '_blank', "width=400,height=500");
    }

    $scope.disconnectDropbox = function() {
        projectService.disconnectDropbox();
    }

    var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/project/linkedAccounts");
    valueRef.on('value', function(result) {
        $scope.linkedAccounts = result.val();
        $timeout(function(){$scope.$digest();});
    });

    var backendBaseURI = "https://bluize.backstagelive.co/v1";
    var value = {"project":projectService.current.project.tag, "type":"APP_REPORT"};
    $http.post(backendBaseURI + '/report', value).then(function(result) {
        console.log('push success');
        console.log(result);
        $scope.appReport = result.data.APP_REPORT;
    }, function(error) {
        console.log('push error');
        console.log(error);
    });

});


angular.module('content')
.controller('contentDetailCtrl',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $mdDialog, $timeout) {
    "ngInject";

    // error message on save/create
    $scope.errorMsg = undefined;
    $scope.busy = false;

    $scope.schema = projectService.current.project.schemas[$stateParams.schemaIndex].data;
    $scope.initData = $stateParams.initData;
    $scope.projectTag = projectService.current.project.tag;

    // Initialise schema value
    $scope.schemaValue = {audience:null};
    $scope.detailsForm = {};

    function convertDateStringsToObjects(schemaValue)
    {
        // convert all string dates into date objects
        for (var idx in schemaValue.data)
        {
            var d = schemaValue.data[idx];
            var parsedDate = Date.parse(d);
            // You want to check again for !isNaN(parsedDate) here because Dates can be converted
            // to numbers, but a failed Date parse will not.
            if (isNaN(d) && !isNaN(parsedDate)) {
                /* store Date object in value */
                //schemaValue.data[idx] = new Date(d);
            }
            // sro specific
            if (idx === 'eventTimes')
            {
                for (var idx2 in d)
                {
                    var sroDate = new Date(d[idx2].eventTime);
                    schemaValue.data[idx][idx2]['eventTime'] = sroDate;
                }
            }
        }    
    }

    if ($stateParams.initData)
    {
        $scope.action = "create";

        $scope.schemaValue = {data: $stateParams.initData, 
                             'initFromUrl': $stateParams.initData.resource};
        convertDateStringsToObjects($scope.schemaValue);
        // for auto complete chips, we need to have the 
        // schemaValue structure created before we save it
        // otherwise the angular autocomplete component throws
        // an exception
        for (var idx in $scope.schema.schema)
        {
            if ($scope.schema.schema[idx].type === "bsl-autocomplete-chips") 
            {
                var key = ($scope.schema.schema[idx].key).replace("data.","");
                $scope.schemaValue.data[key] = $scope.schemaValue.data[key];
            }
        }        
    }
    else if (!$stateParams.uri) {
        // we're creating a new value
        $scope.action = "create";
        $scope.schemaValue.data = {};
        // for auto complete chips, we need to have the 
        // schemaValue structure created before we save it
        // otherwise the angular autocomplete component throws
        // an exception
        for (var idx in $scope.schema.schema)
        {
            if ($scope.schema.schema[idx].type === "bsl-autocomplete-chips") 
            {
                var key = ($scope.schema.schema[idx].key).replace("data.","");
                $scope.schemaValue.data[key] = [];
            }
        }
    } else {
        contentStorageService.getValue($stateParams.uri,$scope.schema.schema)
        .then(function(result) {
            // split into date and time field
            /*if (result.data.date) {
                result.data.date = new Date(result.data.date)
                result.data.time = result.data.date.getHours() + ":" + result.data.date.getMinutes();
            }*/
            $scope.schemaValue = result;
            convertDateStringsToObjects($scope.schemaValue);
            $scope.key = $stateParams.uri.substr($stateParams.uri.lastIndexOf('/')+1);
            // for auto complete chips, we need to have the 
            // schemaValue structure created before we save it
            // otherwise the angular autocomplete component throws
            // an exception
            for (var idx in $scope.schema.schema)
            {
                if ($scope.schema.schema[idx].type === "bsl-autocomplete-chips") 
                {
                    var key = ($scope.schema.schema[idx].key).replace("data.","");
                    if (!(key in $scope.schemaValue.data))
                        $scope.schemaValue.data[key] = [];
                }
            }

            if ($scope.schema.name === "Vouchers") {
                $scope.redeemed = 0;
                var uri = "projectData/" + projectService.current.project.tag + "/user/";
                var valueRef = firebase.database().ref(uri);
                valueRef.once('value').then(function(val) {
                    val = val.val();
                    if (!val)
                        $scope.redeemed = "No";
                    for (var idx in val) {
                        if ('vouchers' in val[idx]) {
                                if ($scope.key in val[idx]['vouchers'])
                                    $scope.redeemed++;
                        }
                    }

                    $timeout(function()
                    {
                        $scope.$apply();
                    });
                });
            }

        });
        $scope.action = "save"
    }

    // for a given value, return the summary for display in a list/title
    $scope.title = function() {
        if ($scope.action == "create") {
            var name = $scope.schema.name || "item";

            return name;
        } else {
            var name = undefined;

            if ($scope.schema.summary && $scope.schemaValue.data) {
                name = $scope.schemaValue.data[$scope.schema.summary];
            }

            name = name || "item";
            return name;
        }
    }

    $scope.save = function() {

        $scope.busy = true;

        // add the time to the date object
        /*if ($scope.schemaValue.data.time && $scope.schemaValue.data.date)
        {
            var hours = parseInt($scope.schemaValue.data.time.split(':')[0], 10);
            var min = parseInt($scope.schemaValue.data.time.split(':')[1], 10);

            $scope.schemaValue.data.date.setHours(hours);
            $scope.schemaValue.data.date.setMinutes(min);
        }*/

        // get the latest member group data as they may have been updated
        var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/memberGroups/");
        valueRef.once('value', function(result) {
            
            // remove angular properties if any (e.g. $$hashkey)
            $scope.schemaValue = angular.copy($scope.schemaValue);

            // HACK specific to "audience" field. 
            // update the criteria on save for the audience, just in case it was modified 
            var memberData = result.val();
            if ('audience' in $scope.schemaValue.data) {
                for (var idx in $scope.schemaValue.data.audience) {
                    var val = $scope.schemaValue.data.audience[idx];
                    if (memberData && "id" in val && val.id in memberData) 
                        val.criteria = memberData[val.id].criteria;
                }
            }

            if ($stateParams.uri) {
                var resourceDirty = false;
                var schema = $scope.schema.schema;
                for (var el in schema) {
                    if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                        resourceDirty = true;
                    }
                }
                
                if ($scope.schemaValue.resource && !resourceDirty) {
                    // don't update the resource if it hasn't changed
                    delete $scope.schemaValue.resource;
                }

                var promise = contentStorageService.saveValue($scope.schemaValue, null, schema);
            } else {
                var promise = contentStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
            }

            promise
            .then(function(result) {
                $scope.busy = false;
                $state.go('content.list');    
            })
            .catch(function(result) {
                $scope.errorMsg = "Server error: " + result;
                $scope.busy = false;
            });

        });
    }

    $scope.cancel = function() {
        $state.go('content.list');
    }

});

angular.module('content')
.controller('pushDetailCtrl2',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $timeout) {
    "ngInject";

    $scope.sendInProgress = false;
    $scope.busy = false;

    $scope.selectedItem = null;
    $scope.searchText = null;

    $scope.schemaValue = {data:{audienceChips:[]}};
    $scope.schema = {valueUri:"projectData/" + projectService.current.project.tag + "/publicReadOnly/pushMessages/",schema:[]};

    $scope.to = {autoCompleteUri:"projectData/" + projectService.current.project.tag + "/admin/memberGroups/", 
                 audienceListUri:"projectData/" + projectService.current.project.tag + "/fromBluize/member/", 
                 displayKey:"name",
                 placeholder: "Audience"
                };

    $scope.to2 = {autoCompleteUri:"projectData/" + projectService.current.project.tag + "/publicReadOnly/posts",
                  displayKey:"title",placeholder: "Promoted Post"}
    $scope.selectedChips2 = [];
    $scope.searchText2 = null;
    $scope.getAutocompleteItems2 = function() {
        var deferred = $q.defer();
        var valueRef = firebase.database().ref($scope.to2.autoCompleteUri);
        valueRef.once('value', function(result) {
            var values = [];
            result.forEach(function(childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                childData['display'] = childData[$scope.to2.displayKey];
                childData['id'] = childKey;
                console.log(childData);
                values.push(childData);
            });
             deferred.resolve(values);
        });
        return deferred.promise;
    }
    $scope.autocompleteSelectedItemChange2 = function(item) {
      console.log(item);
      $scope.schemaValue.data.promotedPost = item;
    }
    $scope.transformChip2 = function(chip) {
        // If it is an object, it's already a known chip
        if (angular.isObject(chip)) {
          return chip;
        }
        // Otherwise, create a new one
        return { name: chip, type: 'new' }
    }

    $scope.key = ""
    if ($stateParams.uri) {
       $scope.key = $stateParams.uri.split('/').pop();
    }

    // tinymce content
    $scope.tinymceModel = 'Initial content';

    $scope.viewRecipients = function() {
        $scope.send(true);
    }

    $scope.viewMoreRecipients = function() {
        $scope.recipients = $scope.recipients.concat($scope.allRecipients.slice($scope.recipients.length, $scope.recipients.length+100));
        console.log($scope.recipients);
    }

    $scope.moreRecipients = function() {
        return $scope.allRecipients.length > $scope.recipients.length;
    }

    $scope.getContent = function() {
    console.log('Editor content:', $scope.tinymceModel);
    };

    $scope.setContent = function() {
    $scope.tinymceModel = 'Time: ' + (new Date());
    };

    var dbValues = [
      {text: "Account Available Balance", value: "{{member.AccountAvailableBalance}}"},
      {text:"Account Type", value: "{{member.AccountType}}"},
      {text:"Card Number", value: "{{member.CardNumber}}"},
      {text:"Current Status Points", value: "{{member.CurrentStatusPoints}}"},
      {text:"Current Status Tier", value: "{{member.CurrentStatusTier}}"},
      {text:"Home Venue ID", value: "{{member.SiteId}}"},
      {text:"Home Venue Name", value: "{{member.SiteName}}"},
      {text:"Home Venue URL", value: "{{member.SiteURL}}"},
      {text:"Date Joined", value: "{{member.DateJoined}}"},
      {text:"Email", value: "{{member.Email}}"},
      {text:"First Name", value: "{{member.FirstName}}"},
      {text:"Gender", value: "{{member.Gender}}"},
      {text:"Id", value: "{{member.Id}}"},
      {text:"Last Name", value: "{{member.LastName}}"},
      {text:"Membership Type", value: "{{member.MembershipType}}"},
      {text:"Membership Category", value: "{{member.MembershipCategory}}"},
      {text:"Mobile", value: "{{member.Mobile}}"},
      {text:"Points Balance", value: "{{member.PointsBalance}}"},
      {text:"Points Value", value: "{{member.PointsValue}}"},
      {text:"Post Code", value: "{{member.PostCode}}"},
      {text:"Required Status Points For Next Tier", value: "{{member.RequiredStatusPointsForNextTier}}"}
    ];

    $scope.tinymceOptions = {
        toolbar: 'undo | bold italic formatselect bullist numlist | link image | alignleft aligncenter alignright | mybutton | forecolor backcolor | code',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        plugins : 'advlist autolink link image lists code charmap print preview textcolor colorpicker autoresize',
        theme : 'modern'
    };

    $scope.tinymceOptionsLite = {
        toolbar: 'mybutton',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        theme : 'modern'
    };

    $scope.getAutocompleteItems = function() {
        var deferred = $q.defer();
        var valueRef = firebase.database().ref($scope.to.autoCompleteUri);
        valueRef.once('value', function(result) {
            var values = [];
            values.push({criteria:"true",display:"Everyone (pre-defined group)",name:"Everyone"})

            result.forEach(function(childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                childData['display'] = childData[$scope.to.displayKey];
                childData['id'] = childKey;
                console.log(childData);
                values.push(childData);
            });
            deferred.resolve(values);
        });
        return deferred.promise;
    }
    $scope.autocompleteSelectedItemChange = function(item) {
        console.log($scope.selectedItem);
    }
    $scope.transformChip = function(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }
      // Otherwise, create a new one
      return { name: chip, type: 'new' }
    }

    $scope.send = function() {
        var confirm = $mdDialog.confirm()
            .title('Confirm Send')
            .textContent('Are you sure you\'d like send this push message?')
            .ariaLabel('')
            .ok('Send')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            $scope.sendHelper(false);
        });
    }

    $scope.previewRecipients = function() {
        $scope.sendHelper(true);
    }


    $scope.importTemplate = function(templateId) {

        console.log("importing template");
        console.log(templateId);

        // get the latest member group data as they may have been updated
        var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/publicReadOnly/pushMessages/" + templateId);
        valueRef.once('value', function(snapshot) {
            let emailTemplate = snapshot.val();
            console.log(emailTemplate);
            if (emailTemplate) {
                $scope.schemaValue.data.message = emailTemplate.message||"";
                $scope.schemaValue.data.subject = emailTemplate.subject||"";
            }
            else {
                console.log("Couldnt find template");
            }
            $timeout(function(){$scope.$digest();});
        });
    
    }

    $scope.sendHelper = function(dontCreateQueue) {

        // some client side validation

        if (!$scope.schemaValue.data.message || $scope.schemaValue.data.audienceChips.length===0 || !$scope.schemaValue.data.subject)
        {
            var alert = $mdDialog.alert({
                title: 'Incorrect message format',
                textContent: 'You must have an audience, subject and body.',
                ok: 'Ok'
            });

            $mdDialog
                .show( alert )
                .finally(function() {
                    alert = undefined;
                });

            return;
        }
        else {

            
            var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/project/firebaseProject");
            valueRef.once('value', function(snapshot) { 
                var firebaseProject = snapshot.val();
                // save first
                saveHelper().then(function() {

                    var audience = $scope.schemaValue.data.audienceChips;
                    if (audience.length==0) {
                        var alert = $mdDialog.alert({
                            title: 'No audience',
                            textContent: 'The audience group did not match any members.',
                            ok: 'Ok'
                        });

                        $mdDialog
                            .show( alert )
                            .finally(function() {
                                alert = undefined;
                            });

                        return;
                    }
                    // get the latest member group data as they may have been updated
                    var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/memberGroups/");
                    valueRef.once('value', function(result) {
                            var memberGroups = result.val();
                            groups = {}
                            for (var idx in audience) {
                                var group = null;
                                if ("id" in audience[idx])
                                    if (memberGroups && audience[idx]["id"] in memberGroups) {
                                        group = memberGroups[audience[idx]["id"]];
                                        group["memberGroupId"] = audience[idx]["id"];
                                    }
                                if (group == null)
                                    group = audience[idx];
                                groups[idx] = {};
                                if ("criteria" in group)
                                    groups[idx]["criteria"] = group.criteria;
                                if ("patterns" in group)
                                    groups[idx]["patterns"] = group.patterns;
                                if ("memberGroupId" in group)
                                    groups[idx]["memberGroupId"] = group.memberGroupId;
                            }
                            groups = JSON.stringify(groups)

                            if ($stateParams.uri)
                                pushId = $stateParams.uri.substr($stateParams.uri.lastIndexOf('/') + 1);
                            else
                                pushId = $scope.schemaValue.key;

                            // create a queue id
                            var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/queues/");
                            var queueId = valueRef.push({"created":{".sv":"timestamp"}}).key;
                            console.log("queueId");
                            console.log(queueId);

                            // store queue id for this email
                            var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/publicReadOnly/pushMessages/" + pushId + "/queueId");
                            valueRef.set(queueId).then(function() {

                                var data = {
                                    'groups': groups, 
                                    'queueType' : "sendPush",
                                    'pushId' : pushId,
                                    'queueId': queueId, 
                                    'project': projectService.current.project.tag, 
                                    'initialRequest': true,
                                    'invokeConsumer': !dontCreateQueue,
                                    'dontCreateQueue':dontCreateQueue,
                                    'filterOutDupes':true
                                };


                                if (firebaseProject)
                                    data['firebaseProject'] = firebaseProject;

                                //console.log(data);
                                //return;
                                
                                //var endpointUrl = "https://19l24h8r1d.execute-api.ap-southeast-2.amazonaws.com/dev/queueproducer";
                                var endpointUrl = "https://tcztse8eeh.execute-api.ap-southeast-2.amazonaws.com/dev/queueproducer"
                                $scope.sendInProgress = true;
                                $scope.busy = true;

                                var requestMethod = $http.post;

                                console.log("sending push");
                                console.log(endpointUrl);
                                console.log(data);
                                requestMethod(endpointUrl, data).then(function(result) {
                                    startListeningQueueEvents();
                                    $scope.refreshInsights();                               
                                },
                                function(error) {

                                $scope.sendInProgress = false;
                                console.log('push error');
                                console.log(error);

                                var title = 'Sorry, there was an error while trying to send messages.';
                                
                                var alert = $mdDialog.alert({
                                    title: 'Sorry, there was an error while trying to send messages.',
                                    textContent: "Please try again later.",
                                    ok: 'Ok'
                                });

                                $mdDialog
                                    .show(alert)
                                    .finally(function() {
                                        alert = undefined;
                                    });

                                });

                                console.log(data);
                            });
                    });

                },
                function(error) {
                    console.log('couldnt save');
                    console.log(error);
                });
            });
        }
    }

    $scope.cancel = function() {
        $state.go('content.list');
    }


    function saveHelper() {

        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);


        // add the time to the date object
        if ($scope.schemaValue.data.time && $scope.schemaValue.data.date)
        {
            var hours = parseInt($scope.schemaValue.data.time.split(':')[0], 10);
            var min = parseInt($scope.schemaValue.data.time.split(':')[1], 10);

            $scope.schemaValue.data.date.setHours(hours);
            $scope.schemaValue.data.date.setMinutes(min);
        }

        // strip html and new lines from subject
        if ($scope.schemaValue.data.subject) {
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/<\/?[^>]+(>|$)/g, "");
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/&nbsp;/g, "");
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/\r?\n|\r/g, "");
        }

        // strip html from message
        if ($scope.schemaValue.data.message) {
            $scope.schemaValue.data.message = $scope.schemaValue.data.message.replace(/<\/?[^>]+(>|$)/g, "");
            $scope.schemaValue.data.message = $scope.schemaValue.data.message.replace(/&nbsp;/g, "");
        }

        var promise;
        if ($stateParams.uri) {
            var resourceDirty = false;
            var schema = $scope.schema.schema;
            for (var el in schema) {
                if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                    resourceDirty = true;
                }
            }
            
            if ($scope.schemaValue.resource && !resourceDirty) {
                // don't update the resource if it hasn't changed
                delete $scope.schemaValue.resource;
            }

            promise = contentStorageService.saveValue($scope.schemaValue, null, schema);
        } else {
            promise = contentStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
            // we now have a key so construct the $stateParams.uri so that
            // next we save we don't cerate a new one 
            $stateParams.uri = $scope.schema.valueUri + $scope.schemaValue.key;
            $scope.schemaValue.uri = $stateParams.uri;
        }

        return promise;
    }

    $scope.save = function(dontExit) {
        
        $scope.busy = true;
        var promise = saveHelper();
        
        promise.then(function(result) {
            $scope.busy = false;
            //setDetailsFormPristine();
            if ($scope.detailsForm)
                $scope.detailsForm.$dirty = false;
            if ($scope.audienceChipForm)
                $scope.audienceChipForm.$dirty = false;
            $timeout(function(){$scope.$digest();});
            /*if (!dontExit) {
                $state.go('content.list'); 
            }*/
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });

    }

    if (!$stateParams.uri) {
        // we're creating a new value
        $scope.action = "create";
    } else {
        contentStorageService.getValue($stateParams.uri)
        .then(function(result) {
            // split into date and time field
            /*if (result.data.date) {
                result.data.date = new Date(result.data.date)
                result.data.time = result.data.date.getHours() + ":" + result.data.date.getMinutes();
            }*/
            $scope.schemaValue = result;
            //convertDateStringsToObjects($scope.schemaValue);
            if (!('audienceChips' in $scope.schemaValue.data)) {
                $scope.schemaValue.data.audienceChips=[];
            }
            $scope.selectedItem2 = $scope.schemaValue.data.promotedPost;

        });
        $scope.action = "publish"
    }
    
    $scope.metrics = null;
    $scope.meta = {};
    $scope.meta.queueEvents = [];

    $scope.SUCCESS_CREATING_JOB_QUEUE = false;

    let publicFacingQueueStatus = {
        "SUCCESS_CREATING_JOB_QUEUE":"Push List Created",
        "CREATING_JOB_QUEUE":"Creating Push List",
        "SUCCESS_GENERATING_MEMBER_COHORT":"Target Member Group Created",
        "GENERATING_MEMBER_COHORT":"Creating Target Member Group",
        "INVOKING_CONSUMER_TO_PROCESS_JOB_QUEUE":"Sending Push Notifications",
        "CONSUMED_SOME_MESSAGES":"Still Sending Push Notifications",
        "CONSUMED_ALL_MESSAGES_DELETING_QUEUE":"Completed Sending Push Notifications",
        "NO_MEMBERS_IN_COHORT_NO_EMAILS_SENT": "Zero Members In Target Group - Stopping"
    }

    function startListeningQueueEvents() {
        if ($stateParams.uri) {
            var emailId = $stateParams.uri.split('/').pop();
            // Listen to queue events
            valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/publicReadOnly/pushMessages/" + emailId + "/queueId");
            valueRef.once('value', function(snapshot) {
                var queueId = snapshot.val();

                if (queueId) {
                    console.log("got valid queueId");
                    console.log(queueId);

                    valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/queues/" + queueId + "/events");
                    // remove previous listeners if any
                    valueRef.off();
                    valueRef.on('value', function(result) {
                        console.log(result.val());
                        let queueEvents = result.val();
                        
                        $scope.queueEvents = [];

                        for (var idx in queueEvents) {
                            let status = queueEvents[idx].data;

                            let publicEvent = {
                                "name":status.name,
                                "title":publicFacingQueueStatus[status.name],
                                "date":status.updated,
                                "data":status.data,
                                "more":false
                            };

                            if (status.name == 'SUCCESS_CREATING_JOB_QUEUE') {
                                $scope.meta.date = status.updated;
                            }

                            if (status.name=="SUCCESS_GENERATING_MEMBER_COHORT") {
                                publicEvent['more'] = true;
                                $scope.meta.total = status.data.numMembersInGroupMinusOptOutsAndDupes||"NA";
                            }

                            if (status.name=="CONSUMED_SOME_MESSAGES") {
                                publicEvent['title'] = "Sent " + status.data.approxNumMessagesConsumed + " messages. Still sending.";
                                $scope.meta.total = status.data.numMembersInGroupMinusOptOutsAndDupes||"NA";
                            }

                            if (status.name.includes("EXCEPTION") || 
                                status.name=="CONSUMED_ALL_MESSAGES_DELETING_QUEUE" || 
                                status.name=="NO_MEMBERS_IN_COHORT_NO_EMAILS_SENT" ||
                                status.name=="SUCCESS_GENERATING_MEMBER_COHORT") {
                                if (status.name.includes("EXCEPTION"))
                                    publicEvent['title'] = "Error occurred during sending. Stopping.";
                                $scope.busy = false;
                            }
                            else {
                                $scope.busy = true;
                            }
                            
                            $scope.queueEvents.push(publicEvent);

                        }

                        $timeout(function(){$scope.$digest();});
                    });
                }
            });
        }
    }
    startListeningQueueEvents()

    $scope.refreshInsightsBusy = false;
    $scope.refreshInsights = function() {
        if ($stateParams.uri) {
            $scope.refreshInsightsBusy = true;
            var emailQueId = $stateParams.uri.split('/').pop();
            valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/emailNotificationsSummary/" + emailQueId);
            valueRef.on('value', function(result) {
                $scope.notifications = result.val();
                console.log($scope.notifications);
                if ($scope.notifications) {
                    var delivered = 0, sent = 0, open = 0, click = 0, bounced = 0, total = 0, date = "";

                    if ($scope.notifications['Delivery'])
                        delivered = $scope.notifications['Delivery']['count'];
                    if ($scope.notifications['Send'])
                        sent = $scope.notifications['Send']['count'];
                    if ($scope.notifications['Open'])
                        open = $scope.notifications['Open']['count'];
                    if ($scope.notifications['Click'])
                        click = $scope.notifications['Click']['count'];
                    if ($scope.notifications['Bounce'])
                        bounced = $scope.notifications['Bounce']['count'];
                    if ($scope.notifications['Total']) {
                        total = $scope.notifications['Total']['count'];
                        date = new Date($scope.notifications['Total']['updated']*1000);
                    }
                    else {
                        total = "N/A";
                        date = "N/A";
                    }

                    $scope.metrics = [
                        {name:"Date", "value":date},
                        {name:"Total Emails To Send", "value":total},
                        {name:"Sent", "value":sent}, 
                        {name:"Delivered", "value":delivered},
                        {name:"Opened", "value":open}, 
                        {name:"Clicked", "value":click}, 
                        {name:"Bounced", "value":bounced}
                    ];

                    $scope.refreshInsightsBusy = false;

                    $timeout(function(){
                        $scope.$digest();
                    });
                }
                else {
                    $scope.refreshInsightsBusy = false;
                    $timeout(function(){
                        $scope.$digest();
                    });
                }

            }, function(){$scope.refreshInsightsBusy = false;});
        }
    }
    $scope.refreshInsights();

    $scope.viewEventDetails = function(event) {
        $mdDialog.show({
          controller: DialogController,
          template: '\
            <md-dialog aria-label="Event Details">\
              <form ng-cloak>\
                <md-toolbar>\
                  <div class="md-toolbar-tools">\
                    <h2>Event {{event.title}}</h2>\
                    <span flex></span>\
                  </div>\
                </md-toolbar>\
                <md-dialog-content ng-if="event.name===\'SUCCESS_GENERATING_MEMBER_COHORT\'">\
                  <div class="md-dialog-content">\
                          <div layout="row" >\
                            <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroup}}</div>\
                              <div>Members</div>\
                            </div>\
                             <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroup-event.data.numMembersInGroupMinusOptOuts}}</div>\
                              <div>Opted Out or No Push Token</div>\
                            </div>\
                             <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroupMinusOptOutsAndDupes}}</div>\
                              <div>Unique Members</div>\
                            </div>\
                          </div>\
                          <md-subheader style="margin-top:20px" class="md-no-sticky">Sample of members in group</md-subheader>\
                          <md-list>\
                              <md-list-item class="md-3-line" ng-repeat="(key,item) in event.data.sampleOfMemberRecords">\
                              <div class="md-list-item-text" layout="column">\
                                    <h3>{{ item.FirstName }} {{ item.LastName }}</h3>\
                                    <h4>{{ item.Email }}</h4>\
                                    <p>{{ item.Id }}</p>\
                                    <p ng-if="item.SiteName">{{ item.SiteName }}</p>\
                              </div>\
                              </md-list-item>\
                          </md-list>\
                  </div>\
                </md-dialog-content>\
                <md-dialog-actions layout="row">\
                  <md-button ng-disabled="disabled" ng-click="cancel()">\
                   Ok\
                  </md-button>\
                </md-dialog-actions>\
              </form>\
            </md-dialog>\
          ',
          parent: angular.element(document.body),
          locals: {event:event},
          clickOutsideToClose:true,
        });
    }

    function DialogController($scope, $mdDialog, event) {
            console.log(event);

            $scope.event = event;
            $scope.disabled = false;

            $scope.hide = function() {
              $mdDialog.hide();
            };

            $scope.cancel = function() {
              $mdDialog.cancel();
            };

            $scope.purchase = function() {
            };
     }

});

angular.module('content')
.controller('emailDetailCtrl2',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $timeout) {
    "ngInject";

    $scope.sendInProgress = false;
    $scope.busy = false;

    $scope.selectedItem = null;
    $scope.searchText = null;

    $scope.schemaValue = {data:{audienceChips:[]}};
    $scope.schema = {valueUri:"projectData/" + projectService.current.project.tag + "/admin/emails/",schema:[]};

    $scope.to = {autoCompleteUri:"projectData/" + projectService.current.project.tag + "/admin/memberGroups/", 
                 audienceListUri:"projectData/" + projectService.current.project.tag + "/fromBluize/member/", 
                 displayKey:"name",
                 placeholder: "Audience"
                };

    $scope.key = ""
    if ($stateParams.uri) {
       $scope.key = $stateParams.uri.split('/').pop();
    }

    // tinymce content
    $scope.tinymceModel = 'Initial content';

    $scope.viewRecipients = function() {
        $scope.send(true);
    }

    $scope.viewMoreRecipients = function() {
        $scope.recipients = $scope.recipients.concat($scope.allRecipients.slice($scope.recipients.length, $scope.recipients.length+100));
        console.log($scope.recipients);
    }

    $scope.moreRecipients = function() {
        return $scope.allRecipients.length > $scope.recipients.length;
    }

    $scope.getContent = function() {
    console.log('Editor content:', $scope.tinymceModel);
    };

    $scope.setContent = function() {
    $scope.tinymceModel = 'Time: ' + (new Date());
    };

    var dbValues = [
      {text: "Account Available Balance", value: "{{member.AccountAvailableBalance}}"},
      {text:"Account Type", value: "{{member.AccountType}}"},
      {text:"Card Number", value: "{{member.CardNumber}}"},
      {text:"Current Status Points", value: "{{member.CurrentStatusPoints}}"},
      {text:"Current Status Tier", value: "{{member.CurrentStatusTier}}"},
      {text:"Home Venue ID", value: "{{member.SiteId}}"},
      {text:"Home Venue Name", value: "{{member.SiteName}}"},
      {text:"Home Venue URL", value: "{{member.SiteURL}}"},
      {text:"Date Joined", value: "{{member.DateJoined}}"},
      {text:"Email", value: "{{member.Email}}"},
      {text:"First Name", value: "{{member.FirstName}}"},
      {text:"Gender", value: "{{member.Gender}}"},
      {text:"Id", value: "{{member.Id}}"},
      {text:"Last Name", value: "{{member.LastName}}"},
      {text:"Membership Type", value: "{{member.MembershipType}}"},
      {text:"Membership Category", value: "{{member.MembershipCategory}}"},
      {text:"Mobile", value: "{{member.Mobile}}"},
      {text:"Points Balance", value: "{{member.PointsBalance}}"},
      {text:"Points Value", value: "{{member.PointsValue}}"},
      {text:"Post Code", value: "{{member.PostCode}}"},
      {text:"Required Status Points For Next Tier", value: "{{member.RequiredStatusPointsForNextTier}}"}
    ];

    $scope.tinymceOptions = {
        toolbar: 'undo | bold italic formatselect bullist numlist | link image | alignleft aligncenter alignright | mybutton | forecolor backcolor | code',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        plugins : 'advlist autolink link image lists code charmap print preview textcolor colorpicker autoresize',
        theme : 'modern'
    };

    $scope.tinymceOptionsLite = {
        toolbar: 'mybutton',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        theme : 'modern'
    };

    $scope.getAutocompleteItems = function() {
        var deferred = $q.defer();
        var valueRef = firebase.database().ref($scope.to.autoCompleteUri);
        valueRef.once('value', function(result) {
            var values = [];
            values.push({criteria:"true",display:"Everyone (pre-defined group)",name:"Everyone"})

            result.forEach(function(childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                childData['display'] = childData[$scope.to.displayKey];
                childData['id'] = childKey;
                console.log(childData);
                values.push(childData);
            });
            deferred.resolve(values);
        });
        return deferred.promise;
    }
    $scope.autocompleteSelectedItemChange = function(item) {
        console.log($scope.selectedItem);
    }
    $scope.transformChip = function(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }
      // Otherwise, create a new one
      return { name: chip, type: 'new' }
    }

    $scope.send = function() {
        var confirm = $mdDialog.confirm()
            .title('Confirm Send')
            .textContent('Are you sure you\'d like send this email?')
            .ariaLabel('')
            .ok('Send')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            $scope.sendHelper(false);
        });
    }

    $scope.previewRecipients = function() {
        $scope.sendHelper(true);
    }


    $scope.importTemplate = function(templateId) {

        console.log("importing template");
        console.log(templateId);

        // get the latest member group data as they may have been updated
        var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/emails/" + templateId);
        valueRef.once('value', function(snapshot) {
            let emailTemplate = snapshot.val();
            console.log(emailTemplate);
            if (emailTemplate) {
                $scope.schemaValue.data.body = emailTemplate.body||"";
                $scope.schemaValue.data.subject = emailTemplate.subject||"";
            }
            else {
                console.log("Couldnt find template");
            }
            $timeout(function(){$scope.$digest();});
        });
    
    }

    $scope.sendHelper = function(dontCreateQueue) {

        // some client side validation

        if (!$scope.schemaValue.data.body || $scope.schemaValue.data.audienceChips.length===0 || !$scope.schemaValue.data.subject)
        {
            var alert = $mdDialog.alert({
                title: 'Incorrect email format',
                textContent: 'You must have an audience, subject and email body.',
                ok: 'Ok'
            });

            $mdDialog
                .show( alert )
                .finally(function() {
                    alert = undefined;
                });

            return;
        }
        else {

            // save first
            saveHelper().then(function() {

                var audience = $scope.schemaValue.data.audienceChips;
                if (audience.length==0) {
                    var alert = $mdDialog.alert({
                        title: 'No audience',
                        textContent: 'The audience group did not match any members.',
                        ok: 'Ok'
                    });

                    $mdDialog
                        .show( alert )
                        .finally(function() {
                            alert = undefined;
                        });

                    return;
                }
                // get the latest member group data as they may have been updated
                var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/memberGroups/");
                valueRef.once('value', function(result) {
                        var memberGroups = result.val();
                        groups = {}
                        for (var idx in audience) {
                            var group = null;
                            if ("id" in audience[idx])
                                if (memberGroups && audience[idx]["id"] in memberGroups) {
                                    group = memberGroups[audience[idx]["id"]];
                                    group["memberGroupId"] = audience[idx]["id"];
                                }
                            if (group == null)
                                group = audience[idx];
                            groups[idx] = {};
                            if ("criteria" in group)
                                groups[idx]["criteria"] = group.criteria;
                            if ("patterns" in group)
                                groups[idx]["patterns"] = group.patterns;
                            if ("memberGroupId" in group)
                                groups[idx]["memberGroupId"] = group.memberGroupId;
                        }
                        groups = JSON.stringify(groups)

                        if ($stateParams.uri)
                            emailId = $stateParams.uri.substr($stateParams.uri.lastIndexOf('/') + 1);
                        else
                            emailId = $scope.schemaValue.key;

                        // create a queue id
                        var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/queues/");
                        var queueId = valueRef.push({"created":{".sv":"timestamp"}}).key;
                        console.log("queueId");
                        console.log(queueId);

                        // store queue id for this email
                        var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/emails/" + emailId + "/queueId");
                        valueRef.set(queueId).then(function() {

                            var data = {
                                'groups': groups, 
                                'queueType' : "sendEmail",
                                'emailId' : emailId,
                                'queueId': queueId, 
                                'project': projectService.current.project.tag, 
                                'initialRequest': true,
                                'invokeConsumer': !dontCreateQueue,
                                'dontCreateQueue':dontCreateQueue,
                                'filterOutDupes':true
                            };

                            console.log(data);
                            //return;
                            
                            //var endpointUrl = "https://19l24h8r1d.execute-api.ap-southeast-2.amazonaws.com/dev/queueproducer";
                            var endpointUrl = "https://tcztse8eeh.execute-api.ap-southeast-2.amazonaws.com/dev/queueproducer";
                            $scope.sendInProgress = true;
                            $scope.busy = true;

                            var requestMethod = $http.post;

                            requestMethod(endpointUrl, data).then(function(result) {
                                startListeningQueueEvents();
                                $scope.refreshInsights();                               
                            },
                            function(error) {

                            $scope.sendInProgress = false;
                            console.log('email error');
                            console.log(error);

                            var title = 'Sorry, there was an error while trying to send emails.';
                            
                            var alert = $mdDialog.alert({
                                title: 'Sorry, there was an error while trying to send emails.',
                                textContent: "Please try again later.",
                                ok: 'Ok'
                            });

                            $mdDialog
                                .show(alert)
                                .finally(function() {
                                    alert = undefined;
                                });

                            });

                            console.log(data);
                        });
                });

            },
            function(error) {
                console.log('couldnt save');
                console.log(error);
            });
        }
    }

    $scope.cancel = function() {
        $state.go('content.list');
    }


    function saveHelper() {

        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);


        // add the time to the date object
        if ($scope.schemaValue.data.time && $scope.schemaValue.data.date)
        {
            var hours = parseInt($scope.schemaValue.data.time.split(':')[0], 10);
            var min = parseInt($scope.schemaValue.data.time.split(':')[1], 10);

            $scope.schemaValue.data.date.setHours(hours);
            $scope.schemaValue.data.date.setMinutes(min);
        }

        // strip html and new lines from subject
        if ($scope.schemaValue.data.subject) {
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/<\/?[^>]+(>|$)/g, "");
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/&nbsp;/g, "");
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/\r?\n|\r/g, "");
        }

        var promise;
        if ($stateParams.uri) {
            var resourceDirty = false;
            var schema = $scope.schema.schema;
            for (var el in schema) {
                if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                    resourceDirty = true;
                }
            }
            
            if ($scope.schemaValue.resource && !resourceDirty) {
                // don't update the resource if it hasn't changed
                delete $scope.schemaValue.resource;
            }

            promise = contentStorageService.saveValue($scope.schemaValue, null, schema);
        } else {
            promise = contentStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
            // we now have a key so construct the $stateParams.uri so that
            // next we save we don't cerate a new one 
            $stateParams.uri = $scope.schema.valueUri + $scope.schemaValue.key;
            $scope.schemaValue.uri = $stateParams.uri;
        }

        return promise;
    }

    $scope.save = function(dontExit) {
        
        $scope.busy = true;
        var promise = saveHelper();
        
        promise.then(function(result) {
            $scope.busy = false;
            //setDetailsFormPristine();
            if ($scope.detailsForm)
                $scope.detailsForm.$dirty = false;
            if ($scope.audienceChipForm)
                $scope.audienceChipForm.$dirty = false;
            $timeout(function(){$scope.$digest();});
            /*if (!dontExit) {
                $state.go('content.list'); 
            }*/
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });

    }

    if (!$stateParams.uri) {
        // we're creating a new value
        $scope.action = "create";
    } else {
        contentStorageService.getValue($stateParams.uri)
        .then(function(result) {
            // split into date and time field
            /*if (result.data.date) {
                result.data.date = new Date(result.data.date)
                result.data.time = result.data.date.getHours() + ":" + result.data.date.getMinutes();
            }*/
            $scope.schemaValue = result;
            //convertDateStringsToObjects($scope.schemaValue);
            if (!('audienceChips' in $scope.schemaValue.data)) {
                $scope.schemaValue.data.audienceChips=[];
            }

        });
        $scope.action = "publish"
    }
    
    $scope.metrics = null;
    $scope.meta = {};
    $scope.meta.queueEvents = [];

    $scope.SUCCESS_CREATING_JOB_QUEUE = false;

    let publicFacingQueueStatus = {
        "SUCCESS_CREATING_JOB_QUEUE":"Email List Created",
        "CREATING_JOB_QUEUE":"Creating Email List",
        "SUCCESS_GENERATING_MEMBER_COHORT":"Target Member Group Created",
        "GENERATING_MEMBER_COHORT":"Creating Target Member Group",
        "INVOKING_CONSUMER_TO_PROCESS_JOB_QUEUE":"Sending Emails",
        "CONSUMED_SOME_MESSAGES":"Still Sending Emails",
        "CONSUMED_ALL_MESSAGES_DELETING_QUEUE":"Completed Sending Emails",
        "NO_MEMBERS_IN_COHORT_NO_EMAILS_SENT": "Zero Members In Target Group - Stopping"
    }

    function startListeningQueueEvents(emailId) {
        if ($stateParams.uri) {
            var emailId = $stateParams.uri.split('/').pop();
            // Listen to queue events
            valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/emails/" + emailId + "/queueId");
            valueRef.once('value', function(snapshot) {
                var queueId = snapshot.val();

                if (queueId) {
                    console.log("got valid queueId");
                    console.log(queueId);

                    valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/queues/" + queueId + "/events");
                    // remove previous listeners if any
                    valueRef.off();
                    valueRef.on('value', function(result) {
                        console.log(result.val());
                        let queueEvents = result.val();
                        
                        $scope.queueEvents = [];

                        for (var idx in queueEvents) {
                            let status = queueEvents[idx].data;

                            let publicEvent = {
                                "name":status.name,
                                "title":publicFacingQueueStatus[status.name],
                                "date":status.updated,
                                "data":status.data,
                                "more":false
                            };

                            if (status.name == 'SUCCESS_CREATING_JOB_QUEUE') {
                                $scope.meta.date = status.updated;
                            }

                            if (status.name=="SUCCESS_GENERATING_MEMBER_COHORT") {
                                publicEvent['more'] = true;
                                $scope.meta.total = status.data.numMembersInGroupMinusOptOutsAndDupes||"NA";
                            }

                            if (status.name=="CONSUMED_SOME_MESSAGES") {
                                publicEvent['title'] = "Sent " + status.data.approxNumMessagesConsumed + " emails. Still sending.";
                                $scope.meta.total = status.data.numMembersInGroupMinusOptOutsAndDupes||"NA";
                            }

                            if (status.name.includes("EXCEPTION") || 
                                status.name=="CONSUMED_ALL_MESSAGES_DELETING_QUEUE" || 
                                status.name=="NO_MEMBERS_IN_COHORT_NO_EMAILS_SENT" ||
                                status.name=="SUCCESS_GENERATING_MEMBER_COHORT") {
                                if (status.name.includes("EXCEPTION"))
                                    publicEvent['title'] = "Error occurred during sending. Stopping.";
                                $scope.busy = false;
                            }
                            else {
                                $scope.busy = true;
                            }
                            
                            $scope.queueEvents.push(publicEvent);

                        }

                        $timeout(function(){$scope.$digest();});
                    });
                }
            });
        }
    }
    startListeningQueueEvents()

    $scope.refreshInsightsBusy = false;
    $scope.refreshInsights = function() {
        if ($stateParams.uri) {
            $scope.refreshInsightsBusy = true;
            var emailQueId = $stateParams.uri.split('/').pop();
            valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/emailNotificationsSummary/" + emailQueId);
            valueRef.on('value', function(result) {
                $scope.notifications = result.val();
                console.log($scope.notifications);
                if ($scope.notifications) {
                    var delivered = 0, sent = 0, open = 0, click = 0, bounced = 0, total = 0, date = "";

                    if ($scope.notifications['Delivery'])
                        delivered = $scope.notifications['Delivery']['count'];
                    if ($scope.notifications['Send'])
                        sent = $scope.notifications['Send']['count'];
                    if ($scope.notifications['Open'])
                        open = $scope.notifications['Open']['count'];
                    if ($scope.notifications['Click'])
                        click = $scope.notifications['Click']['count'];
                    if ($scope.notifications['Bounce'])
                        bounced = $scope.notifications['Bounce']['count'];
                    if ($scope.notifications['Total']) {
                        total = $scope.notifications['Total']['count'];
                        date = new Date($scope.notifications['Total']['updated']*1000);
                    }
                    else {
                        total = "N/A";
                        date = "N/A";
                    }

                    $scope.metrics = [
                        {name:"Date", "value":date},
                        {name:"Total Emails To Send", "value":total},
                        {name:"Sent", "value":sent}, 
                        {name:"Delivered", "value":delivered},
                        {name:"Opened", "value":open}, 
                        {name:"Clicked", "value":click}, 
                        {name:"Bounced", "value":bounced}
                    ];

                    $scope.refreshInsightsBusy = false;

                    $timeout(function(){
                        $scope.$digest();
                    });
                }
                else {
                    $scope.refreshInsightsBusy = false;
                    $timeout(function(){
                        $scope.$digest();
                    });
                }

            }, function(){$scope.refreshInsightsBusy = false;});
        }
    }
    $scope.refreshInsights();

    $scope.viewEventDetails = function(event) {
        $mdDialog.show({
          controller: DialogController,
          template: '\
            <md-dialog aria-label="Event Details">\
              <form ng-cloak>\
                <md-toolbar>\
                  <div class="md-toolbar-tools">\
                    <h2>Event {{event.title}}</h2>\
                    <span flex></span>\
                  </div>\
                </md-toolbar>\
                <md-dialog-content ng-if="event.name===\'SUCCESS_GENERATING_MEMBER_COHORT\'">\
                  <div class="md-dialog-content">\
                          <div layout="row" >\
                            <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroup}}</div>\
                              <div>Members</div>\
                            </div>\
                             <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroup-event.data.numMembersInGroupMinusOptOuts}}</div>\
                              <div>Opted Out or No Email</div>\
                            </div>\
                             <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroupMinusOptOutsAndDupes}}</div>\
                              <div>Unique Members</div>\
                            </div>\
                          </div>\
                          <md-subheader style="margin-top:20px" class="md-no-sticky">Sample of members in group</md-subheader>\
                          <md-list>\
                              <md-list-item class="md-3-line" ng-repeat="(key,item) in event.data.sampleOfMemberRecords">\
                              <div class="md-list-item-text" layout="column">\
                                    <h3>{{ item.FirstName }} {{ item.LastName }}</h3>\
                                    <h4>{{ item.Email }}</h4>\
                                    <p>{{ item.Id }}</p>\
                                    <p ng-if="item.SiteName">{{ item.SiteName }}</p>\
                              </div>\
                              </md-list-item>\
                          </md-list>\
                  </div>\
                </md-dialog-content>\
                <md-dialog-actions layout="row">\
                  <md-button ng-disabled="disabled" ng-click="cancel()">\
                   Ok\
                  </md-button>\
                </md-dialog-actions>\
              </form>\
            </md-dialog>\
          ',
          parent: angular.element(document.body),
          locals: {event:event},
          clickOutsideToClose:true,
        });
    }

    function DialogController($scope, $mdDialog, event) {
            console.log(event);

            $scope.event = event;
            $scope.disabled = false;

            $scope.hide = function() {
              $mdDialog.hide();
            };

            $scope.cancel = function() {
              $mdDialog.cancel();
            };

            $scope.purchase = function() {
            };
     }

});

angular.module('content')
.controller('emailDetailCtrl',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $timeout) {
    "ngInject";

    $scope.sendInProgress = false;

    $scope.selectedItem = null;
    $scope.searchText = null;

    $scope.schemaValue = {data:{audienceChips:[]}};
    $scope.schema = {valueUri:"projectData/" + projectService.current.project.tag + "/admin/emails/",schema:[]};

    $scope.to = {autoCompleteUri:"projectData/" + projectService.current.project.tag + "/admin/memberGroups/", 
                 audienceListUri:"projectData/" + projectService.current.project.tag + "/fromBluize/member/", 
                 displayKey:"name",
                 placeholder: "Audience"
                };

    // tinymce content
    $scope.tinymceModel = 'Initial content';

    $scope.viewRecipients = function() {
        $scope.send(true);
    }

    $scope.viewMoreRecipients = function() {
        $scope.recipients = $scope.recipients.concat($scope.allRecipients.slice($scope.recipients.length, $scope.recipients.length+100));
        console.log($scope.recipients);
    }

    $scope.moreRecipients = function() {
        return $scope.allRecipients.length > $scope.recipients.length;
    }

    $scope.getContent = function() {
    console.log('Editor content:', $scope.tinymceModel);
    };

    $scope.setContent = function() {
    $scope.tinymceModel = 'Time: ' + (new Date());
    };

    var dbValues = [
      {text: "Account Available Balance", value: "{{member.AccountAvailableBalance}}"},
      {text:"Account Type", value: "{{member.AccountType}}"},
      {text:"Card Number", value: "{{member.CardNumber}}"},
      {text:"Current Status Points", value: "{{member.CurrentStatusPoints}}"},
      {text:"Current Status Tier", value: "{{member.CurrentStatusTier}}"},
      {text:"Home Venue ID", value: "{{member.SiteId}}"},
      {text:"Home Venue Name", value: "{{member.SiteName}}"},
      {text:"Home Venue URL", value: "{{member.SiteURL}}"},
      {text:"Date Joined", value: "{{member.DateJoined}}"},
      {text:"Email", value: "{{member.Email}}"},
      {text:"First Name", value: "{{member.FirstName}}"},
      {text:"Gender", value: "{{member.Gender}}"},
      {text:"Id", value: "{{member.Id}}"},
      {text:"Last Name", value: "{{member.LastName}}"},
      {text:"Membership Type", value: "{{member.MembershipType}}"},
      {text:"Membership Category", value: "{{member.MembershipCategory}}"},
      {text:"Mobile", value: "{{member.Mobile}}"},
      {text:"Points Balance", value: "{{member.PointsBalance}}"},
      {text:"Points Value", value: "{{member.PointsValue}}"},
      {text:"Post Code", value: "{{member.PostCode}}"},
      {text:"Required Status Points For Next Tier", value: "{{member.RequiredStatusPointsForNextTier}}"}
    ];

    $scope.tinymceOptions = {
        toolbar: 'undo | bold italic formatselect bullist numlist | link image | alignleft aligncenter alignright | mybutton | forecolor backcolor | code',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        plugins : 'advlist autolink link image lists code charmap print preview textcolor colorpicker autoresize',
        theme : 'modern'
    };

    $scope.tinymceOptionsLite = {
        toolbar: 'mybutton',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        theme : 'modern'
    };

    $scope.getAutocompleteItems = function() {
        var deferred = $q.defer();
        var valueRef = firebase.database().ref($scope.to.autoCompleteUri);
        valueRef.once('value', function(result) {
            var values = [];
            values.push({criteria:"true",display:"Everyone (pre-defined group)",name:"Everyone"})

            result.forEach(function(childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                childData['display'] = childData[$scope.to.displayKey];
                childData['id'] = childKey;
                console.log(childData);
                values.push(childData);
            });
            deferred.resolve(values);
        });
        return deferred.promise;
    }
    $scope.autocompleteSelectedItemChange = function(item) {
        console.log($scope.selectedItem);
    }
    $scope.transformChip = function(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }
      // Otherwise, create a new one
      return { name: chip, type: 'new' }
    }

    $scope.send = function(isTest) {
        if (isTest) {
            $scope.sendHelper(isTest);
        }
        else {
            var confirm = $mdDialog.confirm()
                .title('Confirm Send')
                .textContent('Are you sure you\'d like send this email?')
                .ariaLabel('')
                .ok('Send')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                $scope.sendHelper(isTest);
            });
        }
    }

    $scope.sendHelper = function(isTest) {

        // some client side validation

        if (!isTest && (!$scope.schemaValue.data.body || $scope.schemaValue.data.audienceChips.length===0 || !$scope.schemaValue.data.subject))
        {
            var alert = $mdDialog.alert({
                title: 'Incorrect email format',
                textContent: 'You must have an audience, subject and email body.',
                ok: 'Ok'
            });

            $mdDialog
                .show( alert )
                .finally(function() {
                    alert = undefined;
                });

            return;
        }
        else {
            if (isTest) {
                if (!$scope.schemaValue.data.body)
                    $scope.schemaValue.data.body = " ";
                if (!$scope.schemaValue.data.subject)
                    $scope.schemaValue.data.subject = " ";
            }

            // save first
            saveHelper().then(function() {



                var audience = $scope.schemaValue.data.audienceChips;
                if (audience.length==0) {
                    var alert = $mdDialog.alert({
                        title: 'No audience',
                        textContent: 'The audience group did not match any members.',
                        ok: 'Ok'
                    });

                    $mdDialog
                        .show( alert )
                        .finally(function() {
                            alert = undefined;
                        });

                    return;
                }
                // get the latest member group data as they may have been updated
                var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/memberGroups/");
                valueRef.once('value', function(result) {
                        var memberGroups = result.val();
                        groups = {}
                        for (var idx in audience) {
                            var group = null;
                            if ("id" in audience[idx])
                                if (memberGroups && audience[idx]["id"] in memberGroups)
                                    group = memberGroups[audience[idx]["id"]];
                            if (group == null)
                                group = audience[idx];
                            groups[idx] = {};
                            if ("criteria" in group)
                                groups[idx]["criteria"] = group.criteria;
                            if ("patterns" in group)
                                groups[idx]["patterns"] = group.patterns;
                        }
                        groups = JSON.stringify(groups)

                        if ($stateParams.uri)
                            emailId = $stateParams.uri.substr($stateParams.uri.lastIndexOf('/') + 1);
                        else
                            emailId = $scope.schemaValue.key;

                        //var data = {'stressTestEmail':'heredatedr@inaby.com', 'groups':groups, emailId:emailId, project:projectService.current.project.tag};
                        var data = {'groups':groups, emailId:emailId, project:projectService.current.project.tag, 'initialRequest':true};

                        var endpointUrl = "https://bluize.backstagelive.co/mailinglist";
                        $scope.sendInProgress = true;

                        var requestMethod = $http.post;
                        //isTest = false;
                        if (isTest) {
                            requestMethod = $http.put;
                            data.initialRequest = false;
                        }

                        requestMethod(endpointUrl, data).then(function(result) {
                            console.log('email success');
                            console.log(result);
                            if (isTest) {
                                $scope.sendInProgress = false;
                                $scope.allRecipients = [];
                                for (var key in result.data) {
                                    $scope.allRecipients.push(result.data[key]);
                                }
                                $scope.recipients = $scope.allRecipients.slice(0, 100);
                            }
                            else {
                                startListening(true);
                            }
                        },
                        function(error) {

                        $scope.sendInProgress = false;
                        console.log('email error');
                        console.log(error);

                        var title = 'Sorry, there was an error while trying to send emails.';
                        if (isTest) {
                            title = 'Sorry, there was an error while trying to view recipients.';
                        }
                        
                        var alert = $mdDialog.alert({
                            title: 'Sorry, there was an error while trying to send emails.',
                            textContent: "Please try again later.",
                            ok: 'Ok'
                        });

                        $mdDialog
                            .show(alert)
                            .finally(function() {
                                alert = undefined;
                            });

                        });

                        console.log(data);
                });

            },
            function(error) {
                console.log('couldnt save');
                console.log(error);
            });
        }
    }

    $scope.cancel = function() {
        $state.go('content.list');
    }


    function saveHelper() {

        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);


        // add the time to the date object
        if ($scope.schemaValue.data.time && $scope.schemaValue.data.date)
        {
            var hours = parseInt($scope.schemaValue.data.time.split(':')[0], 10);
            var min = parseInt($scope.schemaValue.data.time.split(':')[1], 10);

            $scope.schemaValue.data.date.setHours(hours);
            $scope.schemaValue.data.date.setMinutes(min);
        }

        // strip html and new lines from subject
        if ($scope.schemaValue.data.subject) {
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/<\/?[^>]+(>|$)/g, "");
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/&nbsp;/g, "");
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/\r?\n|\r/g, "");
        }

        var promise;
        if ($stateParams.uri) {
            var resourceDirty = false;
            var schema = $scope.schema.schema;
            for (var el in schema) {
                if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                    resourceDirty = true;
                }
            }
            
            if ($scope.schemaValue.resource && !resourceDirty) {
                // don't update the resource if it hasn't changed
                delete $scope.schemaValue.resource;
            }

            promise = contentStorageService.saveValue($scope.schemaValue, null, schema);
        } else {
            promise = contentStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
            // we now have a key so construct the $stateParams.uri so that
            // next we save we don't cerate a new one 
            $stateParams.uri = $scope.schema.valueUri + $scope.schemaValue.key;
            $scope.schemaValue.uri = $stateParams.uri;
        }

        return promise;
    }

    $scope.save = function(dontExit) {
        
        $scope.busy = true;
        var promise = saveHelper();
        
        promise.then(function(result) {
            $scope.busy = false;
            if (!dontExit) {
                $state.go('content.list'); 
            }
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });

    }

    if (!$stateParams.uri) {
        // we're creating a new value
        $scope.action = "create";
    } else {
        contentStorageService.getValue($stateParams.uri)
        .then(function(result) {
            // split into date and time field
            /*if (result.data.date) {
                result.data.date = new Date(result.data.date)
                result.data.time = result.data.date.getHours() + ":" + result.data.date.getMinutes();
            }*/
            $scope.schemaValue = result;
            //convertDateStringsToObjects($scope.schemaValue);
            if (!('audienceChips' in $scope.schemaValue.data)) {
                $scope.schemaValue.data.audienceChips=[];
            }

        });
        $scope.action = "publish"
    }
    
    $scope.metrics = [
        {name:"Total Emails To Send", "value":0},
        {name:"Sent", "value":0}, 
        {name:"Delivered", "value":0},
        {name:"Opened", "value":0}, 
        {name:"Clicked", "value":0}, 
        {name:"Bounced", "value":0}
    ];
    $scope.meta = {};
    $scope.meta.queueEvents = {};

    function startListening(ignoreFirst) {
        if ($stateParams.uri) {
            var emailQueId = $stateParams.uri.split('/').pop();
            var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/emailQueue/" + emailQueId + "/meta");
            // remove previous listeners
            valueRef.off();
            valueRef.on('value', function(result) {
                if (ignoreFirst)
                    ignoreFirst = false;
                else {
                    console.log(result.val());
                    $scope.meta = result.val();
                    if ($scope.meta) {
                        $scope.meta.date = new Date($scope.meta.date*1000);
                        $scope.sendInProgress = ($scope.meta.total > $scope.meta.sent);
                        //$timeout(function(){$scope.$digest();});
                    }
                    var valueRef2 = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/emailQueue/" + emailQueId + "/items");
                    valueRef2.once('value').then(function(result){
                        let items = result.val();
                        $scope.sendInProgress = (items != null);
                        $timeout(function(){$scope.$digest();});
                    });
                }
            });
            
            valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/emailNotifications/" + emailQueId);
            // remove previous listeners
            valueRef.off();
            valueRef.on('value', function(result) {
                if (ignoreFirst)
                    ignoreFirst = false;
                else {
                    //console.log(result.val());
                    $scope.notifications = result.val();
                    if ($scope.notifications) {
                        var delivered = 0, sent = 0, open = 0, click = 0, bounced = 0;
                        for (var idx in $scope.notifications) {
                            if ($scope.notifications[idx].eventType=="Delivery") {
                                delivered++;
                            }
                            else if ($scope.notifications[idx].eventType=="Sent") {
                                sent++;
                            }
                            else if ($scope.notifications[idx].eventType=="Open") {
                                open++;
                            }
                            else if ($scope.notifications[idx].eventType=="Click") {
                                click++;
                            }
                            else if ($scope.notifications[idx].eventType=="Bounce") {
                                bounced++;
                            }
                        }

                        $scope.metrics = [
                            {name:"Total Emails To Send", "value":$scope.meta.total},
                            {name:"Sent", "value":$scope.meta.sent}, 
                            {name:"Delivered", "value":delivered},
                            {name:"Opened", "value":open}, 
                            {name:"Clicked", "value":click}, 
                            {name:"Bounced", "value":bounced}
                        ];
                        $timeout(function(){$scope.$digest();});
                    }
                }
            });

        }
    }
    startListening();

});



angular.module('content')
.controller('smsDetailCtrl',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $timeout) {
    "ngInject";

    $scope.sendInProgress = false;

    $scope.selectedItem = null;
    $scope.searchText = null;

    $scope.schemaValue = {data:{audienceChips:[]}};
    $scope.schema = {valueUri:"projectData/" + projectService.current.project.tag + "/admin/sms/",schema:[]};

    $scope.to = {autoCompleteUri:"projectData/" + projectService.current.project.tag + "/admin/memberGroups/", 
                 audienceListUri:"projectData/" + projectService.current.project.tag + "/fromBluize/member/", 
                 displayKey:"name",
                 placeholder: "Audience"
                };

    // tinymce content
    $scope.tinymceModel = 'Initial content';

    $scope.viewRecipients = function() {
        $scope.send(true);
    }

    $scope.viewMoreRecipients = function() {
        $scope.recipients = $scope.recipients.concat($scope.allRecipients.slice($scope.recipients.length, $scope.recipients.length+100));
        console.log($scope.recipients);
    }

    $scope.moreRecipients = function() {
        return $scope.allRecipients.length > $scope.recipients.length;
    }

    $scope.getContent = function() {
    console.log('Editor content:', $scope.tinymceModel);
    };

    $scope.setContent = function() {
    $scope.tinymceModel = 'Time: ' + (new Date());
    };

    var dbValues = [
      {text: "Account Available Balance", value: "{{member.AccountAvailableBalance}}"},
      {text:"Account Type", value: "{{member.AccountType}}"},
      {text:"Card Number", value: "{{member.CardNumber}}"},
      {text:"Current Status Points", value: "{{member.CurrentStatusPoints}}"},
      {text:"Current Status Tier", value: "{{member.CurrentStatusTier}}"},
      {text:"Home Venue ID", value: "{{member.SiteId}}"},
      {text:"Home Venue Name", value: "{{member.SiteName}}"},
      {text:"Home Venue URL", value: "{{member.SiteURL}}"},
      {text:"Date Joined", value: "{{member.DateJoined}}"},
      {text:"Email", value: "{{member.Email}}"},
      {text:"First Name", value: "{{member.FirstName}}"},
      {text:"Gender", value: "{{member.Gender}}"},
      {text:"Id", value: "{{member.Id}}"},
      {text:"Last Name", value: "{{member.LastName}}"},
      {text:"Membership Type", value: "{{member.MembershipType}}"},
      {text:"Membership Category", value: "{{member.MembershipCategory}}"},
      {text:"Mobile", value: "{{member.Mobile}}"},
      {text:"Points Balance", value: "{{member.PointsBalance}}"},
      {text:"Points Value", value: "{{member.PointsValue}}"},
      {text:"Post Code", value: "{{member.PostCode}}"},
      {text:"Required Status Points For Next Tier", value: "{{member.RequiredStatusPointsForNextTier}}"}
    ];

    $scope.tinymceOptions = {
        toolbar: 'undo | bold italic formatselect bullist numlist | link image | alignleft aligncenter alignright | mybutton | forecolor backcolor | code',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        plugins : 'advlist autolink link image lists code charmap print preview textcolor colorpicker autoresize',
        theme : 'modern'
    };

    $scope.tinymceOptionsLite = {
        toolbar: 'mybutton',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        theme : 'modern'
    };

    $scope.getAutocompleteItems = function() {
        var deferred = $q.defer();
        var valueRef = firebase.database().ref($scope.to.autoCompleteUri);
        valueRef.once('value', function(result) {
            var values = [];
            values.push({criteria:"true",display:"Everyone (pre-defined group)",name:"Everyone"})

            result.forEach(function(childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                childData['display'] = childData[$scope.to.displayKey];
                childData['id'] = childKey;
                console.log(childData);
                values.push(childData);
            });
            deferred.resolve(values);
        });
        return deferred.promise;
    }
    $scope.autocompleteSelectedItemChange = function(item) {
        console.log($scope.selectedItem);
    }
    $scope.transformChip = function(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }
      // Otherwise, create a new one
      return { name: chip, type: 'new' }
    }

    $scope.send = function(isTest) {
        if (isTest) {
            $scope.sendHelper(isTest);
        }
        else {
            var confirm = $mdDialog.confirm()
                .title('Confirm Send')
                .textContent('Are you sure you\'d like send this SMS message?')
                .ariaLabel('')
                .ok('Send')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                $scope.sendHelper(isTest);
            });
        }
    }

    $scope.sendHelper = function(isTest) {

        // some client side validation

        if (!isTest && ($scope.schemaValue.data.audienceChips.length===0 || !$scope.schemaValue.data.message))
        {
            var alert = $mdDialog.alert({
                title: 'Incorrectmessage format',
                textContent: 'You must have an audience and message body.',
                ok: 'Ok'
            });

            $mdDialog
                .show( alert )
                .finally(function() {
                    alert = undefined;
                });

            return;
        }
        else {
            if (isTest) {
                if (!$scope.schemaValue.data.message)
                    $scope.schemaValue.data.message = " ";
            }

            // save first
            saveHelper().then(function() {

                var audience = $scope.schemaValue.data.audienceChips;
                if (audience.length==0) {
                    var alert = $mdDialog.alert({
                        title: 'No audience',
                        textContent: 'The audience group did not match any members.',
                        ok: 'Ok'
                    });

                    $mdDialog
                        .show( alert )
                        .finally(function() {
                            alert = undefined;
                        });

                    return;
                }
                // get the latest member group data as they may have been updated
                var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/memberGroups/");
                valueRef.once('value', function(result) {
                        var memberGroups = result.val();
                        groups = {}
                        for (var idx in audience) {
                            var group = null;
                            if ("id" in audience[idx])
                                if (memberGroups && audience[idx]["id"] in memberGroups)
                                    group = memberGroups[audience[idx]["id"]];
                            if (group == null)
                                group = audience[idx];
                            groups[idx] = {};
                            if ("criteria" in group)
                                groups[idx]["criteria"] = group.criteria;
                            if ("patterns" in group)
                                groups[idx]["patterns"] = group.patterns;
                        }
                        groups = JSON.stringify(groups)

                        if ($stateParams.uri)
                            smsId = $stateParams.uri.substr($stateParams.uri.lastIndexOf('/') + 1);
                        else
                            smsId = $scope.schemaValue.key;

                        //var data = {'stressTestEmail':'heredatedr@inaby.com', 'groups':groups, emailId:emailId, project:projectService.current.project.tag};
                        var data = {'groups':groups, smsId:smsId, project:projectService.current.project.tag, 'initialRequest':true};

                        //var endpointUrl = "https://19l24h8r1d.execute-api.ap-southeast-2.amazonaws.com/dev/sms";
                        var endpointUrl = "https://tcztse8eeh.execute-api.ap-southeast-2.amazonaws.com/dev/sms"
                        $scope.sendInProgress = true;

                        var requestMethod = $http.post;
                        //isTest = false;
                        if (isTest) {
                            requestMethod = $http.put;
                            data.initialRequest = false;
                        }

                        requestMethod(endpointUrl, data).then(function(result) {
                            console.log('sms success');
                            console.log(result);
                            if (isTest) {
                                $scope.sendInProgress = false;
                                $scope.allRecipients = [];
                                for (var key in result.data) {
                                    $scope.allRecipients.push(result.data[key]);
                                }
                                $scope.recipients = $scope.allRecipients.slice(0, 100);
                            }
                            else {
                                startListening(true);
                                startListeningToSmsMeta(true);
                            }
                        },
                        function(error) {

                        $scope.sendInProgress = false;
                        console.log('sms error');
                        console.log(error);

                        var title = 'Sorry, there was an error while trying to send messages.';
                        if (isTest) {
                            title = 'Sorry, there was an error while trying to view recipients.';
                        }
                        
                        var alert = $mdDialog.alert({
                            title: 'Sorry, there was an error while trying to send messages.',
                            textContent: "Please try again later.",
                            ok: 'Ok'
                        });

                        $mdDialog
                            .show(alert)
                            .finally(function() {
                                alert = undefined;
                            });

                        });

                        console.log(data);
                });

            },
            function(error) {
                console.log('couldnt save');
                console.log(error);
            });
        }
    }

    $scope.cancel = function() {
        $state.go('content.list');
    }


    function saveHelper() {

        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);


        // add the time to the date object
        if ($scope.schemaValue.data.time && $scope.schemaValue.data.date)
        {
            var hours = parseInt($scope.schemaValue.data.time.split(':')[0], 10);
            var min = parseInt($scope.schemaValue.data.time.split(':')[1], 10);

            $scope.schemaValue.data.date.setHours(hours);
            $scope.schemaValue.data.date.setMinutes(min);
        }

        // strip html and new lines from message
        if ($scope.schemaValue.data.message) {
            $scope.schemaValue.data.message = $scope.schemaValue.data.message.replace(/<\/?[^>]+(>|$)/g, "");
            $scope.schemaValue.data.message = $scope.schemaValue.data.message.replace(/&nbsp;/g, " ");
            $scope.schemaValue.data.message = $scope.schemaValue.data.message.replace(/\r?\n|\r/g, "");
        }

        var promise;
        if ($stateParams.uri) {
            var resourceDirty = false;
            var schema = $scope.schema.schema;
            for (var el in schema) {
                if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                    resourceDirty = true;
                }
            }
            
            if ($scope.schemaValue.resource && !resourceDirty) {
                // don't update the resource if it hasn't changed
                delete $scope.schemaValue.resource;
            }

            promise = contentStorageService.saveValue($scope.schemaValue, null, schema);
        } else {
            promise = contentStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
            // we now have a key so construct the $stateParams.uri so that
            // next we save we don't cerate a new one 
            $stateParams.uri = $scope.schema.valueUri + $scope.schemaValue.key;
            $scope.schemaValue.uri = $stateParams.uri;
        }

        return promise;
    }

    $scope.save = function(dontExit) {
        
        $scope.busy = true;
        var promise = saveHelper();
        
        promise.then(function(result) {
            $scope.busy = false;
            if (!dontExit) {
                $state.go('content.list'); 
            }
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });

    }

    if (!$stateParams.uri) {
        // we're creating a new value
        $scope.action = "create";
    } else {
        contentStorageService.getValue($stateParams.uri)
        .then(function(result) {
            // split into date and time field
            /*if (result.data.date) {
                result.data.date = new Date(result.data.date)
                result.data.time = result.data.date.getHours() + ":" + result.data.date.getMinutes();
            }*/
            $scope.schemaValue = result;
            //convertDateStringsToObjects($scope.schemaValue);
            if (!('audienceChips' in $scope.schemaValue.data)) {
                $scope.schemaValue.data.audienceChips=[];
            }

        });
        $scope.action = "publish"
    }
    
    $scope.metrics = [
        {name:"Total Messages To Send", "value":0},
        {name:"Sent", "value":0}, 
        {name:"Delivered", "value":0},
        {name:"Opened", "value":0}, 
        {name:"Clicked", "value":0}, 
        {name:"Bounced", "value":0}
    ];
    $scope.meta = {};

    function startListeningToSmsMeta(ignoreFist) {
        if ($stateParams.uri) {
            var smsId = $stateParams.uri.split('/').pop();
            var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/sms/" + smsId + "/meta");
            // remove previous listeners
            valueRef.off();
            valueRef.on('value', function(result) {
                $scope.smsMeta = result.val();
                $timeout(function(){$scope.$digest();});
            })
        }
    }
    startListeningToSmsMeta();

    function startListening(ignoreFirst) {
        if ($stateParams.uri) {
            var smsQueId = $stateParams.uri.split('/').pop();
            var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/smsQueue/" + smsQueId + "/meta");
            // remove previous listeners
            valueRef.off();
            valueRef.on('value', function(result) {
                if (ignoreFirst)
                    ignoreFirst = false;
                else {
                    console.log(result.val());
                    $scope.meta = result.val();
                    if ($scope.meta) {
                        $scope.meta.date = new Date($scope.meta.date*1000);
                        $scope.sendInProgress = ($scope.meta.total > $scope.meta.sent);
                        $timeout(function(){$scope.$digest();});
                    }
                }
            });
            
            valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/smsNotifications/" + smsQueId);
            // remove previous listeners
            valueRef.off();
            valueRef.on('value', function(result) {
                if (ignoreFirst)
                    ignoreFirst = false;
                else {
                    console.log(result.val());
                    $scope.notifications = result.val();
                    if ($scope.notifications) {
                        var delivered = 0, sent = 0, open = 0, click = 0, bounced = 0;
                        for (var idx in $scope.notifications) {
                            if ($scope.notifications[idx].eventType=="Delivery") {
                                delivered++;
                            }
                            else if ($scope.notifications[idx].eventType=="Sent") {
                                sent++;
                            }
                            else if ($scope.notifications[idx].eventType=="Open") {
                                open++;
                            }
                            else if ($scope.notifications[idx].eventType=="Click") {
                                click++;
                            }
                            else if ($scope.notifications[idx].eventType=="Bounce") {
                                bounced++;
                            }
                        }

                        $scope.metrics = [
                            {name:"Total Emails To Send", "value":$scope.meta.total},
                            {name:"Sent", "value":$scope.meta.sent}, 
                            {name:"Delivered", "value":delivered},
                            {name:"Opened", "value":open}, 
                            {name:"Clicked", "value":click}, 
                            {name:"Bounced", "value":bounced}
                        ];
                        $timeout(function(){$scope.$digest();});
                    }
                }
            });

        }
    }
    startListening();

});

angular.module('content')
.controller('groupsCtrl',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog) {
    "ngInject";

    $scope.fields = [
      {text:"Account Available Balance", value: "member.AccountAvailableBalance"},
      {text:"Account Type", value: "member.AccountType"},
      {text:"Card Number", value: "member.CardNumber"},
      {text:"Current Status Points", value: "member.CurrentStatusPoints"},
      {text:"Current Status Tier", value: "member.CurrentStatusTier"},
      {text:"Home Venue ID", value: "member.SiteId", fieldToUseAsChipValue: "SiteId", autoCompleteUri:"projectData/" + projectService.current.project.tag + "/admin/project/venues"},
      {text:"Date Joined", value: "member.DateJoined"},
      {text:"Email", value: "member.Email"},
      {text:"First Name", value: "member.FirstName"},
      {text:"Gender", value: "member.Gender", fieldToUseAsChipValue: "name", autoCompleteValues:[{name:"M",displayName:"Male"}, {name:"F",displayName:"Female"}, {name:"U",displayName:"Unspecified"}]},
      {text:"Id", value: "member.Id"},
      {text:"Interests", value: "member.Interests", fieldToUseAsChipValue: "name", autoCompleteUri:"projectData/" + projectService.current.project.tag + "/admin/design/app/customisations/interests"},
      {text:"Last Name", value: "member.LastName"},
      {text:"Membership Type", value: "member.MembershipType"},
      {text:"Membership Category", value: "member.MembershipCategory"},
      {text:"Mobile", value: "member.Mobile"},
      {text:"Points Balance", value: "member.PointsBalance"},
      {text:"Points Value", value: "member.PointsValue"},
      {text:"Post Code", value: "member.PostCode"},
      {text:"Required Status Points For Next Tier", value: "member.RequiredStatusPointsForNextTier"},
      {text:"Card Group ID", value: "member.CardGroup"},
      {text:"Active Location", value: "member.ActiveLocation", fieldToUseAsChipValue: "id", autoCompleteUri:"projectData/" + projectService.current.project.tag + "/publicReadOnly/locations"},
    ];


    $scope.fieldsByText = {};
    var textFromValue = {};
    var valueFromText = {};
    for (var idx in $scope.fields) {
        textFromValue[$scope.fields[idx].value] = $scope.fields[idx].text;
        valueFromText[$scope.fields[idx].text] = $scope.fields[idx].value;
        $scope.fieldsByText[$scope.fields[idx].text] = $scope.fields[idx];
    }

    $scope.schemaValue = {name:"",criteria:""};
    $scope.schema = {valueUri:"projectData/" + projectService.current.project.tag + "/admin/memberGroups/",schema:[]};


    $scope.cancel = function() {
        $state.go('content.list');
    }

    function patternFromCriteria(criteria) {
        var regex = ""
        var value = [];
        for (var idx in criteria.value) {
            regex = regex + ".*" + criteria.value[idx].name + "|";
            value.push({name:criteria.value[idx].name});
        }
        regex = regex.slice(0, -1);

        var field = valueFromText[criteria.field];
        field = field.substring(7);
        return {"field":field, "pattern":regex, "value":value};
    }

    function criteriaFromPattern(pattern) {
        var field = textFromValue["member."+pattern.field];
        return {"field":field,"value":pattern.value, "condition":"Matches Any"};
    }

    function saveHelper() {

        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);

        // convert to javascript expression
        var expression = "";
        var patterns = [];
        for (var idx in $scope.criterias) {
            
            if ($scope.criterias[idx].condition === "Matches Any") {
                var pattern = patternFromCriteria($scope.criterias[idx]);
                if (!pattern.pattern) {
                    var alert = $mdDialog.alert({
                    title: 'Value error',
                    textContent: '"Matches Any" value must not be blank.',
                    ok: 'Ok'});

                    $mdDialog
                    .show(alert)
                    .finally(function() {
                      alert = undefined;
                    });

                    return $q.reject();
                }
                else {
                    patterns.push(pattern);
                }
                continue;
            }

            try {
                var expr = criteriaToJavascriptExpression($scope.criterias[idx]);
                var member = {};
                eval(expr); 
            } 
            catch (e) {
                var alert = $mdDialog.alert({
                title: 'Value is incorrect.',
                textContent: "Values with letters (i.e. non-numeric values) must be in quotes \"General Member\"",
                ok: 'Ok'});

                $mdDialog
                .show(alert)
                .finally(function() {
                  alert = undefined;
                });
            
                return $q.reject();
            }

            if (expression != "") {
                expression = expression + "&&" + expr;
            }
            else {
                expression = expr;                
            }
        }

        $scope.schemaValue.data.criteria = expression;
        $scope.schemaValue.data.patterns = patterns;

        // save it
        var promise;
        if ($stateParams.uri) {
            var resourceDirty = false;
            var schema = $scope.schema.schema;
            for (var el in schema) {
                if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                    resourceDirty = true;
                }
            }
            
            if ($scope.schemaValue.resource && !resourceDirty) {
                // don't update the resource if it hasn't changed
                delete $scope.schemaValue.resource;
            }

            promise = contentStorageService.saveValue($scope.schemaValue, null, schema);
        } else {
            promise = contentStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
        }

        return promise;
    }

    $scope.save = function(dontExit) {
        
        $scope.busy = true;
        var promise = saveHelper();
        
        promise.then(function(result) {
            $scope.busy = false;
            if (!dontExit) {
                $state.go('content.list'); 
            }
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });

    }

    $scope.criterias = [];

    if (!$stateParams.uri) {
        // we're creating a new value
        $scope.action = "create";
        $scope.criterias.push({value:[]});
    } else {
        contentStorageService.getValue($stateParams.uri)
        .then(function(result) {

            if ("criteria" in result.data && result.data.criteria != "") {
                //var expression = result.data.criteria.replace(/\s/g,'');
                var expression = result.data.criteria;
                expression=expression.split('&&');
                for (var idx in expression)
                    $scope.criterias.push(javascriptExpressionToCriteria(expression[idx]));
            }

            if ("patterns" in result.data) {
                for (var idx in result.data.patterns)
                    $scope.criterias.push(criteriaFromPattern(result.data.patterns[idx]));
            }

            $scope.schemaValue = result;
        });
        $scope.action = "publish"
    }

    $scope.conditions = [{text:"Is",value:"=="}, {text:"Is not",value:"!="}, {text:"Is greater than",value:">"}, {text:"Is less than",value:"<"}, {text:"Matches Any", value:"pattern"}];
    var textToExpression = {};
    for (var idx in $scope.conditions) {
        textToExpression[$scope.conditions[idx].text] = $scope.conditions[idx].value;
    }

    $scope.add = function() {
        $scope.criterias.push({value:[]});
    }

    $scope.remove = function(index) {
        $scope.criterias.splice(index,1);
    }

    /*
    GS: OLD IMPLEMENTATION, NOW WE DONT FIDDLE WITH THE USER INPUT VALUE

    function criteriaToJavascriptExpression(criteria) {
        var expression = valueFromText[criteria.field];
        var condition = textToExpression[criteria.condition];
        var value = criteria.value[0].name.replace(/["']/g,'');

        // treat anything with a greater than or less than and field
        // Id as numeric
        if (condition === "<" || condition === ">" || expression === "member.SiteId")
            return expression + condition + value;
        else
            return expression + condition + "\"" + value + "\"";
    }*/

    function criteriaToJavascriptExpression(criteria) {
        var expression = valueFromText[criteria.field];
        var condition = textToExpression[criteria.condition];
        var value = criteria.value[0].name;

        var expr = expression + condition + value;

        try {
            var member = {};
            eval(expr);
            return expr;
        } 
        catch (e) {
            return expression + condition + "\"" + value + "\""
        }
    }

    function javascriptExpressionToCriteria(expr) {

        var equalityCondition = expr.split('==');
        var inequalityCondition = expr.split('!=');
        var greaterThanCondition = expr.split('>');
        var lessThanCondition = expr.split('<');

        var criteria = {};

        if (equalityCondition.length>1) {
            criteria.condition = "Is";
            criteria.value = [{name:equalityCondition[1]}];
            criteria.field = textFromValue[equalityCondition[0]];

        }
        else if (inequalityCondition.length>1) {
            criteria.condition = "Is not";
            criteria.value = [{name:inequalityCondition[1]}];  
            criteria.field = textFromValue[inequalityCondition[0]];

        }
        else if (greaterThanCondition.length>1) {
            criteria.condition = "Is greater than";
            criteria.value = [{name:greaterThanCondition[1]}];   
            criteria.field = textFromValue[greaterThanCondition[0]];

        }
        else if (lessThanCondition.length>1) {
            criteria.condition = "Is less than";
            criteria.value = [{name:lessThanCondition[1]}]; 
            criteria.field = textFromValue[lessThanCondition[0]];
        }

        return criteria
    }

    $scope.getMaxChips = function(criteria) {
        if (criteria.condition != "Matches Any") 
            return 1;
        else
            return 0;    
    }
    $scope.onConditionSelect = function(criteria) {
        criteria.value = [];
    }

    $scope.onFieldSelect = function(criteria) {
        criteria.value = [];
        if (criteria.field === "Interests")
            $scope.conditions = [{text:"Matches Any", value:"pattern"}];
        else
            $scope.conditions = [{text:"Is",value:"=="}, {text:"Is not",value:"!="}, {text:"Is greater than",value:">"}, {text:"Is less than",value:"<"}, {text:"Matches Any", value:"pattern"}];
    }

    $scope.getAutocompleteItems = function(criteria) {
        var deferred = $q.defer();

        console.log("getAutocompleteItems:"+criteria);
        if (!('field' in criteria))
            deferred.resolve([]);        
        else if ($scope.fieldsByText[criteria.field].autoCompleteUri) {
            var valueRef = firebase.database().ref($scope.fieldsByText[criteria.field].autoCompleteUri);
            valueRef.once('value', function(result) {
                var values = [];
                result.forEach(function(childSnapshot) {
                    var childKey = childSnapshot.key;
                    var childData = childSnapshot.val();
                    childData.displayName = childData.name;
                    if ("fieldToUseAsChipValue" in $scope.fieldsByText[criteria.field])
                        childData.name = childData[$scope.fieldsByText[criteria.field].fieldToUseAsChipValue];
                    //console.log(childData);
                    values.push(childData);
                });
                values.sort(function(a, b){
                    if(a.displayName < b.displayName) return -1;
                    if(a.displayName > b.displayName) return 1;
                    return 0;                
                });
                deferred.resolve(values);
            },
            function(error) {
                console.log(error);
                deferred.resolve([]);
            });
        }
        else if ($scope.fieldsByText[criteria.field].autoCompleteValues) {
            deferred.resolve($scope.fieldsByText[criteria.field].autoCompleteValues);
        }
        else {
            deferred.resolve([]);
        }
        return deferred.promise;
    }

    $scope.transformChip = function(chip) {
        console.log(chip);
        if (typeof chip === "object" && "name" in chip)
            return {name:chip.name};
        else
            return {name:chip}
    }

});

angular.module('content')
.controller('pushCtrl',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $timeout) {
    "ngInject";

    $scope.sendInProgress = false;

    $scope.selectedItem = null;
    $scope.searchText = null;

    $scope.schemaValue = {data:{audienceChips:[]}};
    $scope.schema = {valueUri:"projectData/" + projectService.current.project.tag + "/publicReadOnly/pushMessages/",schema:[]};

    $scope.to = {autoCompleteUri:"projectData/" + projectService.current.project.tag + "/admin/memberGroups/", 
                 audienceListUri:"projectData/" + projectService.current.project.tag + "/fromBluize/member/", 
                 displayKey:"name",
                 placeholder: "Audience"
                };

    $scope.getAutocompleteItems = function() {
        var deferred = $q.defer();
        var valueRef = firebase.database().ref($scope.to.autoCompleteUri);
        valueRef.once('value', function(result) {
            var values = [];
            values.push({criteria:"true",display:"Everyone (pre-defined group)",name:"Everyone"})

            result.forEach(function(childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                childData['display'] = childData[$scope.to.displayKey];
                childData['id'] = childKey;
                console.log(childData);
                values.push(childData);
            });
            deferred.resolve(values);
        });
        return deferred.promise;
    }
    $scope.autocompleteSelectedItemChange = function(item) {
        console.log($scope.selectedItem);
    }
    $scope.transformChip = function(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }
      // Otherwise, create a new one
      return { name: chip, type: 'new' }
    }

    $scope.send = function() {
        $scope.save().then(function() {
            let key = $stateParams.uri.substring($stateParams.uri.lastIndexOf('/') + 1);
            $scope.sendHelper(key);
        }).catch(function() {
            var alert = $mdDialog.alert({
                title: 'Unexpected error',
                textContent: 'Please try again.',
                ok: 'Ok'
            });

            $mdDialog
            .show( alert )
            .finally(function() {
                alert = undefined;
            });

        });
    };

    $scope.sendHelper = function(key)
    {
        console.log("key");
        console.log(key);
        if (!key) {
            var alert = $mdDialog.alert({
                title: 'Unexpected error',
                textContent: 'Please try again.',
                ok: 'Ok'
            });

            $mdDialog
            .show( alert )
            .finally(function() {
                alert = undefined;
            });
        }
        //return;
        $scope.sendInProgress = true;
        var member = $scope.schemaValue.data.memberId;
        var project = projectService.current.project.tag;
        var title=$scope.schemaValue.data.subject;
        var message=$scope.schemaValue.data.message;
        var audience = $scope.schemaValue.data.audienceChips;
        var groups = {};
        var sendToGroups = false;
        var postId = null;
        if ('promotedPost' in $scope.schemaValue.data && $scope.schemaValue.data.promotedPost != undefined)
            postId = $scope.schemaValue.data.promotedPost.id;

        // get the latest member group data as they may have been updated
        var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/memberGroups/");
        valueRef.once('value', function(result) {

            if (!title || $scope.schemaValue.data.audienceChips.length===0 || !message) 
            {
                $scope.sendInProgress = false;
                var alert = $mdDialog.alert({
                    title: 'Incorrect message format',
                    textContent: 'You must have an audience, subject and message.',
                    ok: 'Ok'
                });

                $mdDialog
                    .show( alert )
                    .finally(function() {
                        alert = undefined;
                    });

                return;
            }

            var memberGroups = result.val();

            for (var idx in audience) {

                var group = null;
                if ("id" in audience[idx]) {
                    id = audience[idx]["id"];
                    if (memberGroups && id in memberGroups)
                        group = memberGroups[id];
                }

                if (group == null)
                    group = audience[idx];
                sendToGroups = true;

                groups[idx] = {};
                if ("criteria" in group)
                    groups[idx]["criteria"] = group.criteria;
                if ("patterns" in group)
                    groups[idx]["patterns"] = group.patterns;
            }
            groups = JSON.stringify(groups)
            var value = "";

            //key = '99';

            if (sendToGroups) {
                var value = {"groups":groups,"project":project,"title":title,"message":message,"postId":postId,"initialRequest":true,"pushId":key};
                if (projectService.current.project.tag==="starRewards" || projectService.current.project.tag==="bobsbb" || projectService.current.project.tag==="senseOfTaste") {
                    value["membersProject"]="qantum";
                }
                value = JSON.stringify(value); 
            }
            else {
                var value = {"member":member,"project":project,"title":title,"message":message,"postId":postId,"initialRequest":true,"pushId":key};
                value = JSON.stringify(value); 
            }

            console.log("sending push: " + value);
            var backendBaseURI = "https://bluize.backstagelive.co/v1";
            if (
                projectService.current.project.tag==="qantumExpo22"||
                projectService.current.project.tag==="nbslc"||
                projectService.current.project.tag==="qantum"||
                projectService.current.project.tag==="qantumDemo"||
                projectService.current.project.tag==="starRewards"||
                projectService.current.project.tag==="bobsbb" || 
                projectService.current.project.tag==="utopiaGaming" || 
                projectService.current.project.tag==="senseOfTaste" || 
                projectService.current.project.tag==="universalRewards" ||
                projectService.current.project.tag==="northShoreTavern" ||
                projectService.current.project.tag==="manlyHarbourBoatClub" ||
                projectService.current.project.tag==="figTreeSports" ||
                projectService.current.project.tag==="clubHillsdale" ||
                projectService.current.project.tag==="marylandMembership" ||
                projectService.current.project.tag==="tghRewards"
            ) 
            {
                //backendBaseURI = "https://d1hs96j6h1gob4.cloudfront.net/v1";
                backendBaseURI = "https://d3682oannlprzj.cloudfront.net/v1";
            }

            $http.post(backendBaseURI + '/push', value).then(function(result) {
              $scope.sendInProgress = false;
              listenToQueue();
              console.log('push success');
              console.log(result);
              $state.go('content.list');
            },
            function(error) {
            $scope.sendInProgress = false;
            console.log('push error');
            console.log(error);
            var alert = $mdDialog.alert({
                title: 'Unable to send push',
                textContent: 'Sorry, we couldn\'t send this push, please try again.',
                ok: 'Ok'
            });

            $mdDialog
                .show( alert )
                .finally(function() {
                  alert = undefined;
                });
            });        

        });        


    }

    $scope.save = function() {

        $scope.busy = true;
        var deferred = $q.defer();

        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);

        if ($stateParams.uri) {
            var resourceDirty = false;
            var schema = $scope.schema.schema;
            for (var el in schema) {
                if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                    resourceDirty = true;
                }
            }
            
            if ($scope.schemaValue.resource && !resourceDirty) {
                // don't update the resource if it hasn't changed
                delete $scope.schemaValue.resource;
            }

            var promise = contentStorageService.saveValue($scope.schemaValue, null, schema);
        } else {
            var promise = contentStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
            $stateParams.uri = $scope.schema.valueUri + $scope.schemaValue.key;
        }

        promise
        .then(function(result) {
            $scope.busy = false;
            onLoad().then(function () {
                console.log("save completed successfully");
                deferred.resolve();
            }).catch(function() {
                deferred.resolve();
            });
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });

        return deferred.promise;

    }

    function listenToQueue() {
        if ($stateParams.uri!=null) {
            let pushId = $stateParams.uri.substring($stateParams.uri.lastIndexOf('/') + 1);
            let pushQueueUrl = "projectData/" + projectService.current.project.tag + "/admin/pushQueue/" + pushId;
            //let pushQueueUrl = "projectData/" + "eurekaHotelGroup" + "/admin/pushQueue/" + "-LlZzFbOcNe4mbSQ2B_i";
            console.log(pushQueueUrl);
            firebase.database().ref(pushQueueUrl).on('value', function(result) {
                try {
                    let queue = result.val();
                    if (queue) {
                        if ('items' in queue) {
                            $scope.remainingMessagesInQueue = Object.keys(queue.items).length;
                        }
                        else {
                            $scope.remainingMessagesInQueue = 0;
                        }
                        if ('meta' in queue) {
                            $scope.date = new Date(queue.meta.date*1000);
                        }
                    }
                    else {
                        $scope.remainingMessagesInQueue = 0;
                    }
                }
                catch(err){
                    $scope.remainingMessagesInQueue = null;
                }
                $timeout(function(){$scope.$digest();});
            });
        }
    }
    listenToQueue();

    function onLoad() {
        var deferred = $q.defer();

        if (!$stateParams.uri) {
            // we're creating a new value
            $scope.action = "create";
            deferred.resolve();
        } else {
            contentStorageService.getValue($stateParams.uri)
            .then(function(result) {
                $scope.schemaValue = result;

                if (!('audienceChips' in $scope.schemaValue.data)) {
                    $scope.schemaValue.data.audienceChips=[];
                }
                $scope.selectedItem2 = $scope.schemaValue.data.promotedPost;

                deferred.resolve();

            });
            $scope.action = "publish"
        }

        return deferred.promise;
    }
    onLoad();

    $scope.cancel = function() {
        $state.go('content.list');
    }    

    $scope.to2 = {autoCompleteUri:"projectData/" + projectService.current.project.tag + "/publicReadOnly/posts",
                  displayKey:"title",placeholder: "Promoted Post"}
    $scope.selectedChips2 = [];
    $scope.searchText2 = null;
    $scope.getAutocompleteItems2 = function() {
        var deferred = $q.defer();
        var valueRef = firebase.database().ref($scope.to2.autoCompleteUri);
        valueRef.once('value', function(result) {
            var values = [];
            result.forEach(function(childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                childData['display'] = childData[$scope.to2.displayKey];
                childData['id'] = childKey;
                console.log(childData);
                values.push(childData);
            });
             deferred.resolve(values);
        });
        return deferred.promise;
    }
    $scope.autocompleteSelectedItemChange2 = function(item) {
      console.log(item);
      $scope.schemaValue.data.promotedPost = item;
    }
  $scope.transformChip2 = function(chip) {
    // If it is an object, it's already a known chip
    if (angular.isObject(chip)) {
      return chip;
    }
    // Otherwise, create a new one
    return { name: chip, type: 'new' }
  }

});

angular.module('content')
.controller('surveyCtrl',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $window, $filter) {
    "ngInject";

    $scope.survey = {};
    var baseUri = "projectData/" + projectService.current.project.tag;

    if (!$stateParams.uri) {
        var name = "Patient Feedback " + $filter('date')(new Date(), 'MMM d, y h:mm:ss a');
        $scope.survey = {_created: firebase.database.ServerValue.TIMESTAMP, "name":name, "type":"PROM_1_0_0", inviteEmailId:null};
        $scope.emails = {
            invite:{
                subject:projectService.current.project.publicFacingName+" Patient Feedback",
                body:"<p>To add the participants first name, add {{member.FirstName}} in the email text.</p><p>To add the participant last name, add {{member.LastName}} in the email text.</p><p>To add&nbsp;a link to the survey, click 'Insert/edit link' then add {{surveyUrl}} as the 'Url' value, like this:</p><p><a href='{{surveyUrl}}'>Take the survey now.</a></p>"
            }
        };

        //create email
        ref = firebase.database().ref(baseUri + "/admin/emails/");
        ref.push($scope.emails.invite).then(function(result) {
            $scope.survey.inviteEmailId = result.key;
            $scope.inviteEmailEndpoint = baseUri + "/admin/emails/" + $scope.survey.inviteEmailId;
            //$stateParams.uri = endpoint + "/" + ref.push().key;
            var ref = firebase.database().ref(baseUri + "/admin/surveys/");
            ref.push($scope.survey).then(function(result) {
                $scope.Id = result.key;
                $stateParams.uri = baseUri + "/admin/surveys/" + result.key;
                console.log("survey created");
                startListening();
            },
            function(error) {
                console.log("cant create survey");
                $state.go('content.list');
            });
        },
        function(error) {
            console.log("cant create email");
            $state.go('content.list');
        });
    }
    else {
        var ref = firebase.database().ref($stateParams.uri);
        ref.once('value', function(result) {
            console.log(result);
            $scope.survey = result.val();
            $scope.Id = result.key;

            $scope.responseByParticipantId = {};
            for (var pid in $scope.survey.responses) {
                var response = $scope.survey.responses[pId];
                $scope.responseByParticipantId[pId].events = [];
                for (var idx2 in response.events) {
                    $scope.responseByParticipantId[pId].events.push(response.events[idx]);
                }
            }

            $scope.inviteEmailEndpoint = baseUri + "/admin/emails/" + $scope.survey.inviteEmailId;
            // get email
            ref = firebase.database().ref($scope.inviteEmailEndpoint);
            ref.once('value', function(result) {
                $scope.emails = {invite:result.val()};
                startListening();
            });
        },
        function(error) {
            console.log("cant load survey");
            $state.go('content.list');            
        });
    }

    $scope.humanState = {"SURVEY_GENERATED":"Invitation sent.", 
        "NO_EMAIL_SENT": "Invitation sent.",
        "RESPONSE_RECEIVED_DROPBOX_NOT_LINKED":"Response received, Dropbox account not linked.",
        "RESPONSE_RECEIVED":"Response received.",
        "RESPONSE_RECEIVED_SAVED_TO_DROPBOX":"Response received, saved to Dropbox.",
        "RESPONSE_RECEIVED_ERROR_SAVING_TO_DROPBOX":"Response received, error saving to Dropbox.",
        "RESPONSE_RECEIVED_ERROR_GENERATING_HL7":"Response received, error generating HL7 file."
    };

    $scope.participantState = function(id) {
        var state = "";
        if ("events" in $scope.survey) {
            if (id in $scope.survey.events) {
                for (var idx in $scope.survey.events[id]) {
                    var event = $scope.survey.events[id][idx];
                    state += event.event + ", ";
                }
            }
        }
        return state.substring(0, state.length - 2);
    }

    $scope.hasParticipantBeenInvited = function(id) {
        if ("events" in $scope.survey) {
            if (id in $scope.survey.events) {
                for (var idx in $scope.survey.events[id]) {
                    var event = $scope.survey.events[id][idx];
                    if (event.event == "SURVEY_SENT")
                        return true;
                }
            }
        }
        return false;
    }

    function stopListening() {
        var ref = firebase.database().ref($stateParams.uri);
        ref.off('value');
    }

    function startListening() {

        var ref = firebase.database().ref($stateParams.uri);
        ref.on('value', function(result) {
            $scope.survey = result.val();
            $scope.responseByParticipantId = {};
            for (var idx in $scope.survey.responses ) {
                var response = $scope.survey.responses[idx];
                var pId = response.participantId;
                $scope.responseByParticipantId[pId] = response;
            }
            $scope.$digest();
        },
        function(error) {
            console.log("couldn't listen");
        });
    
    }
    
    $scope.save = function() {
        firebase.database().ref($stateParams.uri).set($scope.survey).then(function() {
            console.log("survey saved");
        },
        function(error) {
            console.log("cant save survey");
        });
    }

    $scope.saveEmails = function() {
        firebase.database().ref($scope.inviteEmailEndpoint).set($scope.emails.invite).then(function() {
            console.log("email saved");
        },
        function(error) {
            console.log("cant save email");
        });
    }

    $scope.cancel = function() {
        stopListening();
        $state.go('content.list');
    }

    $scope.deleteMissing = function(deleteAll) {

        if (deleteAll) {
            var confirm = $mdDialog.confirm()
                .title('Confirm Delete All')
                .textContent('Are you sure you\'d like to delete all participants? Invited participants won\'t be deleted.')
                .ariaLabel('Secondary click demo')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                stopListening();
                for (var key in $scope.survey.participants) {
                    if (deleteAll || !('Email' in $scope.survey.participants[key])) {
                        if (!$scope.hasParticipantBeenInvited(key)) {
                            var ref = firebase.database().ref($stateParams.uri + "/participants/" + key);
                            ref.remove();
                        }
                    }
                }
                startListening();
            });
        }
        else {
            stopListening();
            for (var key in $scope.survey.participants) {
                if (!('Email' in $scope.survey.participants[key])) {
                    var ref = firebase.database().ref($stateParams.uri + "/participants/" + key);
                    ref.remove();
                }
            }
            startListening();          
        }
        
    }

    $scope.showContent = function($fileContent)
    {
        var participants = [];
        var papa = window.Papa.parse($fileContent);
        var id = 0;
        var ref = firebase.database().ref($stateParams.uri + "/participants");
        stopListening();
        for (var idx in papa.data) {

            var data = papa.data[idx];
            var patientInfo = data[15];
            var fundInfo = data[19];

            if (!fundInfo || !patientInfo || patientInfo.match(/ANAESTHETIST|RECOVERY/))
                continue;

            var participant = {};
            
            patientInfo = patientInfo.replace(/\r/g, " ");
            fundInfo = fundInfo.replace(/\r/g, " ");

            email = /Email:\s*(.+)DOB:/.exec(fundInfo);
            if (email)
                participant.Email = email[1].trim();
            
            dob = /DOB:\s*(\d+\/\d+\/\d+)\s+/.exec(fundInfo);  
            if (dob) {
                participant.DOB = dob[1].trim().split('/');
                participant.DOB = participant.DOB[2] + ("0" + participant.DOB[1]).slice(-2) + ("0" + participant.DOB[0]).slice(-2)
            }

            //participant.FundInfo = fundInfo;
            //participant.PatientInfo = patientInfo;

            // parse first name, last name, title and address
            patientInfo = patientInfo.split(" ");
            if (patientInfo.length>0) {

                var code = patientInfo.shift();
                participant.LastName = patientInfo.shift();

                for (var idx=0, parsingAddress = false, skippedTitle = false; idx < patientInfo.length; idx++) 
                {
                    var name = patientInfo[idx];

                    if (name.length > 0) 
                    {
                        if (parsingAddress) 
                        {
                            if (name === "M:" || name === "H:" || name === "W:")
                                break;
                            else
                                if ('Address' in participant)
                                    participant.Address += " " + name;
                                else
                                    participant.Address = name;                                    
                        }
                        else 
                        { 
                            if (name === name.toUpperCase()) {
                                participant.LastName += " " + name;
                            }
                            else if (!skippedTitle) {
                                // skip the title (MR/MRS) which we assume
                                // is always present
                                skippedTitle = true;
                            }
                            else {
                                participant.FirstName = name;
                                if (isNaN(parseInt(patientInfo[idx+1]))) {
                                    participant.MiddleName = patientInfo[idx+1];
                                    idx++;
                                }
                                parsingAddress = true
                            }
                        }
                    }
                }

                if (email || patientInfo || fundInfo)
                    ref.push(participant);
            }
        }
        startListening();
        /*var ref = firebase.database().ref($stateParams.uri + "/participants");
        stopListening();
        participants.forEach(function(participant) {
            ref.push(participant);
            id++;
            if (id === participants.length)
                startListening();
        });*/

    };

    $scope.deleteParticipant = function (Id) {
        stopListening();
        var participantRef = firebase.database().ref($stateParams.uri + "/participants/" + Id);
        participantRef.remove();
        startListening();
    }

    $scope.sendInProgress = false;
    $scope.sendInvites = function() {
        var confirm = $mdDialog.confirm()
            .title('Confirm Invite')
            .textContent('Are you sure you\'d like to email all participants?')
            .ariaLabel('Secondary click demo')
            .ok('Invite')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {

            $scope.sendInProgress = true;
            // Use only those with valid emails
            var participants = {};
            for (var idx in $scope.survey.participants) {
                var participant = $scope.survey.participants[idx];
                if (participant.Email) {
                    participant.Id = idx;
                    participants[idx] = participant; 
                }
            }

            var value = {
                "project" : "oromax",
                "surveyId" : $scope.Id,
                "emailId": $scope.survey.inviteEmailId,
                "participants" : participants
            };
            value = JSON.stringify(value);

            console.log("sending invites to all participants: ");
            console.log(value);
            var backendBaseURI = "https://bluize.backstagelive.co";
            //return;
            $http.post(backendBaseURI + '/promenginregister', value).then(function(result) {
                $scope.sendInProgress = false;
                console.log('invite success');
                console.log(result);

                var alert = $mdDialog.alert({
                    title: 'Invitations sent',
                    textContent: 'All participants have been invited by email.',
                    ok: 'Ok'
                });

                $mdDialog
                    .show( alert )
                    .finally(function() {
                        alert = undefined;
                    });

              //$state.go('content.list');
            },
            function(error) {
                $scope.sendInProgress = false;
                console.log('push error');
                console.log(error);
            });
        });

    }

    $scope.tinymceOptions = {
        toolbar: 'undo | bold italic formatselect bullist numlist | link image | alignleft aligncenter alignright | mybutton | forecolor backcolor | code',
        menubar: false,
        statusbar: false,
        setup: function(editor) {

        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        plugins : 'advlist autolink link image lists code charmap print preview textcolor colorpicker autoresize',
        theme : 'modern'
    };

});

angular.module('content')
.controller('smsDetailCtrl2',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $timeout) {
    "ngInject";

    $scope.sendInProgress = false;
    $scope.busy = false;

    $scope.selectedItem = null;
    $scope.searchText = null;

    $scope.schemaValue = {data:{audienceChips:[]}};
    $scope.schema = {valueUri:"projectData/" + projectService.current.project.tag + "/admin/sms/",schema:[]};

    $scope.to = {autoCompleteUri:"projectData/" + projectService.current.project.tag + "/admin/memberGroups/", 
                 audienceListUri:"projectData/" + projectService.current.project.tag + "/fromBluize/member/", 
                 displayKey:"name",
                 placeholder: "Audience"
                };

    $scope.key = ""
    if ($stateParams.uri) {
       $scope.key = $stateParams.uri.split('/').pop();
    }

    // tinymce content
    $scope.tinymceModel = 'Initial content';

    $scope.viewRecipients = function() {
        $scope.send(true);
    }

    $scope.viewMoreRecipients = function() {
        $scope.recipients = $scope.recipients.concat($scope.allRecipients.slice($scope.recipients.length, $scope.recipients.length+100));
        console.log($scope.recipients);
    }

    $scope.moreRecipients = function() {
        return $scope.allRecipients.length > $scope.recipients.length;
    }

    $scope.getContent = function() {
    console.log('Editor content:', $scope.tinymceModel);
    };

    $scope.setContent = function() {
    $scope.tinymceModel = 'Time: ' + (new Date());
    };

    var dbValues = [
      {text: "Account Available Balance", value: "{{member.AccountAvailableBalance}}"},
      {text:"Account Type", value: "{{member.AccountType}}"},
      {text:"Card Number", value: "{{member.CardNumber}}"},
      {text:"Current Status Points", value: "{{member.CurrentStatusPoints}}"},
      {text:"Current Status Tier", value: "{{member.CurrentStatusTier}}"},
      {text:"Home Venue ID", value: "{{member.SiteId}}"},
      {text:"Home Venue Name", value: "{{member.SiteName}}"},
      {text:"Home Venue URL", value: "{{member.SiteURL}}"},
      {text:"Date Joined", value: "{{member.DateJoined}}"},
      {text:"Email", value: "{{member.Email}}"},
      {text:"First Name", value: "{{member.FirstName}}"},
      {text:"Gender", value: "{{member.Gender}}"},
      {text:"Id", value: "{{member.Id}}"},
      {text:"Last Name", value: "{{member.LastName}}"},
      {text:"Membership Type", value: "{{member.MembershipType}}"},
      {text:"Membership Category", value: "{{member.MembershipCategory}}"},
      {text:"Mobile", value: "{{member.Mobile}}"},
      {text:"Points Balance", value: "{{member.PointsBalance}}"},
      {text:"Points Value", value: "{{member.PointsValue}}"},
      {text:"Post Code", value: "{{member.PostCode}}"},
      {text:"Required Status Points For Next Tier", value: "{{member.RequiredStatusPointsForNextTier}}"}
    ];

    $scope.tinymceOptions = {
        toolbar: 'undo | bold italic formatselect bullist numlist | link image | alignleft aligncenter alignright | mybutton | forecolor backcolor | code',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        plugins : 'advlist autolink link image lists code charmap print preview textcolor colorpicker autoresize',
        theme : 'modern'
    };

    $scope.tinymceOptionsLite = {
        toolbar: 'mybutton',
        menubar: false,
        statusbar: false,
        setup: function(editor) {
            editor.addButton('mybutton', {
                  type: 'listbox',
                  text: 'Insert Field',
                  icon: false,
                  onselect: function (e) {
                    editor.insertContent(this.value());
                  },
                  values: dbValues,
                  onPostRender: function () {
                    // Select the second item by default
                    //this.value('&nbsp;<em>Some italic text!</em>');
                  }
                });
        },        
        onChange: function(e) {
          // put logic here for keypress and cut/paste changes
        },
        inline: false,
        skin: 'lightgray',
        baseURL: '/dashboard/tinymce',
        theme : 'modern'
    };

    $scope.getAutocompleteItems = function() {
        var deferred = $q.defer();
        var valueRef = firebase.database().ref($scope.to.autoCompleteUri);
        valueRef.once('value', function(result) {
            var values = [];
            values.push({criteria:"true",display:"Everyone (pre-defined group)",name:"Everyone"})

            result.forEach(function(childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                childData['display'] = childData[$scope.to.displayKey];
                childData['id'] = childKey;
                console.log(childData);
                values.push(childData);
            });
            deferred.resolve(values);
        });
        return deferred.promise;
    }
    $scope.autocompleteSelectedItemChange = function(item) {
        console.log($scope.selectedItem);
    }
    $scope.transformChip = function(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }
      // Otherwise, create a new one
      return { name: chip, type: 'new' }
    }

    $scope.send = function() {
        var confirm = $mdDialog.confirm()
            .title('Confirm Send')
            .textContent('Are you sure you\'d like send this sms message?')
            .ariaLabel('')
            .ok('Send')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            $scope.sendHelper(false);
        });
    }

    $scope.previewRecipients = function() {
        $scope.sendHelper(true);
    }

    $scope.sendHelper = function(dontCreateQueue) {

        // some client side validation

        if (!$scope.schemaValue.data.message || $scope.schemaValue.data.audienceChips.length===0)
        {
            var alert = $mdDialog.alert({
                title: 'Incorrect message format',
                textContent: 'You must have an audience, subject and body.',
                ok: 'Ok'
            });

            $mdDialog
                .show( alert )
                .finally(function() {
                    alert = undefined;
                });

            return;
        }
        else {

            // save first
            saveHelper().then(function() {

                var audience = $scope.schemaValue.data.audienceChips;
                if (audience.length==0) {
                    var alert = $mdDialog.alert({
                        title: 'No audience',
                        textContent: 'The audience group did not match any members.',
                        ok: 'Ok'
                    });

                    $mdDialog
                        .show( alert )
                        .finally(function() {
                            alert = undefined;
                        });

                    return;
                }
                // get the latest member group data as they may have been updated
                var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/memberGroups/");
                valueRef.once('value', function(result) {
                        var memberGroups = result.val();
                        groups = {}
                        for (var idx in audience) {
                            var group = null;
                            if ("id" in audience[idx])
                                if (memberGroups && audience[idx]["id"] in memberGroups) {
                                    group = memberGroups[audience[idx]["id"]];
                                    group["memberGroupId"] = audience[idx]["id"];
                                }
                            if (group == null)
                                group = audience[idx];
                            groups[idx] = {};
                            if ("criteria" in group)
                                groups[idx]["criteria"] = group.criteria;
                            if ("patterns" in group)
                                groups[idx]["patterns"] = group.patterns;
                            if ("memberGroupId" in group)
                                groups[idx]["memberGroupId"] = group.memberGroupId;
                        }
                        groups = JSON.stringify(groups)

                        if ($stateParams.uri)
                            pushId = $stateParams.uri.substr($stateParams.uri.lastIndexOf('/') + 1);
                        else
                            pushId = $scope.schemaValue.key;

                        // create a queue id
                        var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/queues/");
                        var queueId = valueRef.push({"created":{".sv":"timestamp"}}).key;
                        console.log("queueId");
                        console.log(queueId);

                        // store queue id for this email
                        var valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/sms/" + pushId + "/queueId");
                        valueRef.set(queueId).then(function() {

                            var data = {
                                'groups': groups, 
                                'queueType' : "sendSms",
                                'smsId' : pushId,
                                'queueId': queueId, 
                                'project': projectService.current.project.tag, 
                                'initialRequest': true,
                                'invokeConsumer': !dontCreateQueue,
                                'dontCreateQueue':dontCreateQueue,
                                'filterOutDupes':true
                            };

                            console.log(data);
                            //return;
                            
                            //var endpointUrl = "https://19l24h8r1d.execute-api.ap-southeast-2.amazonaws.com/dev/queueproducer";
                            var endpointUrl = "https://tcztse8eeh.execute-api.ap-southeast-2.amazonaws.com/dev/queueproducer";

                            $scope.sendInProgress = true;
                            $scope.busy = true;

                            var requestMethod = $http.post;

                            requestMethod(endpointUrl, data).then(function(result) {
                                startListeningQueueEvents();
                            },
                            function(error) {

                            $scope.sendInProgress = false;
                            console.log('email error');
                            console.log(error);

                            var title = 'Sorry, there was an error while trying to send messages.';
                            
                            var alert = $mdDialog.alert({
                                title: 'Sorry, there was an error while trying to send messages.',
                                textContent: "Please try again later.",
                                ok: 'Ok'
                            });

                            $mdDialog
                                .show(alert)
                                .finally(function() {
                                    alert = undefined;
                                });

                            });

                            console.log(data);
                        });
                });

            },
            function(error) {
                console.log('couldnt save');
                console.log(error);
            });
        }
    }

    $scope.cancel = function() {
        $state.go('content.list');
    }


    function saveHelper() {

        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);


        // add the time to the date object
        if ($scope.schemaValue.data.time && $scope.schemaValue.data.date)
        {
            var hours = parseInt($scope.schemaValue.data.time.split(':')[0], 10);
            var min = parseInt($scope.schemaValue.data.time.split(':')[1], 10);

            $scope.schemaValue.data.date.setHours(hours);
            $scope.schemaValue.data.date.setMinutes(min);
        }

        // strip html and new lines from subject
        if ($scope.schemaValue.data.subject) {
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/<\/?[^>]+(>|$)/g, "");
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/&nbsp;/g, "");
            $scope.schemaValue.data.subject = $scope.schemaValue.data.subject.replace(/\r?\n|\r/g, "");
        }

        // strip html from message
        if ($scope.schemaValue.data.message) {
            $scope.schemaValue.data.message = $scope.schemaValue.data.message.replace(/<\/?[^>]+(>|$)/g, "");
            $scope.schemaValue.data.message = $scope.schemaValue.data.message.replace(/&nbsp;/g, "");
        }

        var promise;
        if ($stateParams.uri) {
            var resourceDirty = false;
            var schema = $scope.schema.schema;
            for (var el in schema) {
                if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                    resourceDirty = true;
                }
            }
            
            if ($scope.schemaValue.resource && !resourceDirty) {
                // don't update the resource if it hasn't changed
                delete $scope.schemaValue.resource;
            }

            promise = contentStorageService.saveValue($scope.schemaValue, null, schema);
        } else {
            promise = contentStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
            // we now have a key so construct the $stateParams.uri so that
            // next we save we don't cerate a new one 
            $stateParams.uri = $scope.schema.valueUri + $scope.schemaValue.key;
            $scope.schemaValue.uri = $stateParams.uri;
        }

        return promise;
    }

    $scope.save = function(dontExit) {
        
        $scope.busy = true;
        var promise = saveHelper();
        
        promise.then(function(result) {
            $scope.busy = false;
            //setDetailsFormPristine();
            if ($scope.detailsForm)
                $scope.detailsForm.$dirty = false;
            if ($scope.audienceChipForm)
                $scope.audienceChipForm.$dirty = false;
            $timeout(function(){$scope.$digest();});
            /*if (!dontExit) {
                $state.go('content.list'); 
            }*/
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });

    }

    if (!$stateParams.uri) {
        // we're creating a new value
        $scope.action = "create";
    } else {
        contentStorageService.getValue($stateParams.uri)
        .then(function(result) {
            // split into date and time field
            /*if (result.data.date) {
                result.data.date = new Date(result.data.date)
                result.data.time = result.data.date.getHours() + ":" + result.data.date.getMinutes();
            }*/
            $scope.schemaValue = result;
            //convertDateStringsToObjects($scope.schemaValue);
            if (!('audienceChips' in $scope.schemaValue.data)) {
                $scope.schemaValue.data.audienceChips=[];
            }
            $scope.selectedItem2 = $scope.schemaValue.data.promotedPost;

        });
        $scope.action = "publish"
    }
    
    $scope.metrics = null;
    $scope.meta = {};
    $scope.meta.queueEvents = [];

    $scope.SUCCESS_CREATING_JOB_QUEUE = false;

    let publicFacingQueueStatus = {
        "SUCCESS_CREATING_JOB_QUEUE":"SMS List Created",
        "CREATING_JOB_QUEUE":"Creating SMS List",
        "SUCCESS_GENERATING_MEMBER_COHORT":"Target Member Group Created",
        "GENERATING_MEMBER_COHORT":"Creating Target Member Group",
        "INVOKING_CONSUMER_TO_PROCESS_JOB_QUEUE":"Sending SMS's",
        "CONSUMED_SOME_MESSAGES":"Still Sending SMS's",
        "CONSUMED_ALL_MESSAGES_DELETING_QUEUE":"Completed Sending SMS's",
        "NO_MEMBERS_IN_COHORT_NO_EMAILS_SENT": "Zero Members In Target Group - Stopping",
        "ERROR_NO_SMS_CREDITS_REMAINING": "You have no more SMS credits remaining. Stopping."
    }

    function startListeningQueueEvents() {
        if ($stateParams.uri) {
            var emailId = $stateParams.uri.split('/').pop();
            // Listen to queue events
            valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/sms/" + emailId + "/queueId");
            valueRef.once('value', function(snapshot) {
                var queueId = snapshot.val();

                if (queueId) {
                    console.log("got valid queueId");
                    console.log(queueId);

                    valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/queues/" + queueId + "/events");
                    // remove previous listeners if any
                    valueRef.off();
                    valueRef.on('value', function(result) {
                        console.log(result.val());
                        let queueEvents = result.val();
                        
                        $scope.queueEvents = [];

                        for (var idx in queueEvents) {
                            let status = queueEvents[idx].data;

                            let publicEvent = {
                                "name":status.name,
                                "title":publicFacingQueueStatus[status.name],
                                "date":status.updated,
                                "data":status.data,
                                "more":false
                            };

                            if (status.name == 'SUCCESS_CREATING_JOB_QUEUE') {
                                $scope.meta.date = status.updated;
                            }

                            if (status.name=="SUCCESS_GENERATING_MEMBER_COHORT") {
                                publicEvent['more'] = true;
                                $scope.meta.total = status.data.numMembersInGroupMinusOptOutsAndDupes||"NA";
                            }

                            if (status.name=="CONSUMED_SOME_MESSAGES") {
                                publicEvent['title'] = "Sent " + status.data.approxNumMessagesConsumed + " messages. Still sending.";
                                $scope.meta.total = status.data.numMembersInGroupMinusOptOutsAndDupes||"NA";
                            }

                            if (status.name.includes("EXCEPTION") || 
                                status.name=="CONSUMED_ALL_MESSAGES_DELETING_QUEUE" || 
                                status.name=="NO_MEMBERS_IN_COHORT_NO_EMAILS_SENT" ||
                                status.name=="SUCCESS_GENERATING_MEMBER_COHORT" ||
                                status.name=="ERROR_NO_SMS_CREDITS_REMAINING") {
                                if (status.name.includes("EXCEPTION"))
                                    publicEvent['title'] = "Error occurred during sending. Stopping.";
                                $scope.busy = false;
                            }
                            else {
                                $scope.busy = true;
                            }
                            
                            $scope.queueEvents.push(publicEvent);

                        }

                        $timeout(function(){$scope.$digest();});
                    });
                }
            });
        }
    }
    startListeningQueueEvents()

    $scope.viewEventDetails = function(event) {
        $mdDialog.show({
          controller: DialogController,
          template: '\
            <md-dialog aria-label="Event Details">\
              <form ng-cloak>\
                <md-toolbar>\
                  <div class="md-toolbar-tools">\
                    <h2>Event {{event.title}}</h2>\
                    <span flex></span>\
                  </div>\
                </md-toolbar>\
                <md-dialog-content ng-if="event.name===\'SUCCESS_GENERATING_MEMBER_COHORT\'">\
                  <div class="md-dialog-content">\
                          <div layout="row" >\
                            <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroup}}</div>\
                              <div>Members</div>\
                            </div>\
                             <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroup-event.data.numMembersInGroupMinusOptOuts}}</div>\
                              <div>Opted Out or No Mobile</div>\
                            </div>\
                             <div layout="column" layout-align="center center" flex>\
                              <div class="md-display-1">{{event.data.numMembersInGroupMinusOptOutsAndDupes}}</div>\
                              <div>Unique Members</div>\
                            </div>\
                          </div>\
                          <md-subheader style="margin-top:20px" class="md-no-sticky">Sample of members in group</md-subheader>\
                          <md-list>\
                              <md-list-item class="md-3-line" ng-repeat="(key,item) in event.data.sampleOfMemberRecords">\
                              <div class="md-list-item-text" layout="column">\
                                    <h3>{{ item.FirstName }} {{ item.LastName }}</h3>\
                                    <h4>{{ item.Mobile }}</h4>\
                                    <p>{{ item.Id }}</p>\
                                    <p ng-if="item.SiteName">{{ item.SiteName }}</p>\
                              </div>\
                              </md-list-item>\
                          </md-list>\
                  </div>\
                </md-dialog-content>\
                <md-dialog-actions layout="row">\
                  <md-button ng-disabled="disabled" ng-click="cancel()">\
                   Ok\
                  </md-button>\
                </md-dialog-actions>\
              </form>\
            </md-dialog>\
          ',
          parent: angular.element(document.body),
          locals: {event:event},
          clickOutsideToClose:true,
        });
    }

    $scope.refreshInsightsBusy = false;
    $scope.refreshInsights = function() {
        if ($stateParams.uri) {
            $scope.refreshInsightsBusy = true;
            var emailQueId = $stateParams.uri.split('/').pop();
            valueRef = firebase.database().ref("projectData/" + projectService.current.project.tag + "/admin/smsNotificationsSummary/" + emailQueId);
            valueRef.on('value', function(result) {
                $scope.notifications = result.val();
                console.log($scope.notifications);
                if ($scope.notifications) {
                    var delivered = 0, sent = 0, open = 0, click = 0, bounced = 0, total = 0, date = "";

                    if ($scope.notifications['smsSent'])
                        sent = $scope.notifications['smsSent']['count'];                   
                    else {
                        total = "N/A";
                        date = "N/A";
                    }

                    $scope.metrics = [
                        {name:"Sent", "value":sent},
                    ];

                    $scope.refreshInsightsBusy = false;

                    $timeout(function(){
                        $scope.$digest();
                    });
                }
                else {
                    $scope.refreshInsightsBusy = false;
                    $timeout(function(){
                        $scope.$digest();
                    });
                }

            }, function(){$scope.refreshInsightsBusy = false;});
        }
    }
    $scope.refreshInsights();

    function DialogController($scope, $mdDialog, event) {
            console.log(event);

            $scope.event = event;
            $scope.disabled = false;

            $scope.hide = function() {
              $mdDialog.hide();
            };

            $scope.cancel = function() {
              $mdDialog.cancel();
            };

            $scope.purchase = function() {
            };
     }

});

angular.module('content').controller('menuGroupCtrl',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $timeout) 
{
    "ngInject";

    $scope.menuGroups = [
        {"label":"Menu", "id":"menu"},
        {"label":"Menu Category", "id":"category" }
    ];


    $scope.errorMsg = undefined;
    $scope.busy = false;

    $scope.menuGroup = {};

    $scope.projectTag = projectService.current.project.tag;
    $scope.schema = projectService.current.project.schemas[$stateParams.schemaIndex].data;

    if ($scope.projectTag==="turnstyle") {
        $scope.menuGroup = {"attribute":"tags","condition":"contains","group":"menu"};
    }

    if ($stateParams.uri) {
        contentStorageService.getValue($stateParams.uri,[]).then(function(result) {
            console.log(result);
            $scope.menuGroup = result.data;
        });
    }

    $scope.menus = [];

    firebase.database().ref("projectData/" + $scope.projectTag + "/publicReadOnly/catalogue/productFilters").on('value', function(res){
        let filters = res.val();
        console.log(filters);
        for (let idx in filters) {
            let filter = filters[idx];
            if (filter.group==="menu") {
                filter.id = idx;
                $scope.menus.push(filter);
            }
        }
    });

    $scope.cancel = function() {
        $state.go('content.list');
    }

    $scope.save = function() {

        $scope.busy = true;
        var deferred = $q.defer();

        // remove angular properties if any (e.g. $$hashkey)
        $scope.menuGroup = angular.copy($scope.menuGroup);

        var promise;
        // save existing value
        if ($stateParams.uri) {
            
            var resourceDirty = false;
            var schema = {};
            console.log($scope.schema);
            console.log($scope.menuGroup);

            var value = {"data":$scope.menuGroup, "uri":$stateParams.uri};

            promise = contentStorageService.saveValue(value, null, schema);

        } 
        // create new value
        else {
            console.log($scope.menuGroup, $scope.schema.valueUri, $scope.schema.schema);
            var value = {"data":$scope.menuGroup, "uri":$stateParams.uri};
            var schema = {};

            promise = contentStorageService.saveValue(value, $scope.schema.valueUri, schema);
            $stateParams.uri = $scope.schema.valueUri + value.key;
        }

        promise
        .then(function(result) {
            $scope.busy = false;
            onLoad().then(function () {
                console.log("save completed successfully");
                deferred.resolve();
            }).catch(function() {
                deferred.resolve();
            });
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });

        return deferred.promise;

    }

});

angular.module('content').controller('qVoucherCtrl',
function (contentStorageService, $state, $stateParams, $scope, projectService, $http, contentTabsService, $q, audienceService, $mdDialog, $timeout) 
{
    "ngInject";

    $scope.times = [
        "00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00",
        "12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"
    ];

    $scope.weekdays = [
        "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"
    ];

    $scope.ratingLevels = [
        {"label":"Every Level", "id":"Every Level"},
        {"label":"Valued", "id":"Valued" },
        {"label":"Silver", "id":"Silver"},
        {"label":"Gold", "id":"Gold"},
        {"label":"Platinum", "id":"Platinum"},
        {"label":"Star Staff", "id":"Star Staff"},
        {"label":"Sponsor", "id":"Sponsor"},
        {"label":"Test", "id":"Test"},
        {"label":"Non Financial", "id":"Non Financial" }
    ];

    $scope.voucherTypes = [
        {"label":"Standard", "id":"standard"},
        {"label":"Member Birthday", "id":"birthday" },
        {"label":"Member Signup", "id":"signup"},
    ];

    $scope.testDate = "";
    $scope.offset = 0;

    $scope.errorMsg = undefined;
    $scope.busy = false;

    //$scope.schema = projectService.current.project.schemas[$stateParams.schemaIndex].data;
    $scope.qVoucher = {};

    $scope.projectTag = projectService.current.project.tag;

    $scope.qVoucher = {"ratingLevels":[], "type":"standard"};

    if ($stateParams.uri) {
        contentStorageService.getValue($stateParams.uri,[]).then(function(result) {
            console.log(result);
            $scope.qVoucher = result.data;
        });
    }

    $scope.cancel = function() {
        $state.go('content.list');
    }

    $scope.saveQVoucher = function() 
    {
         if (!$scope.qVoucher.name||!$scope.qVoucher.description) {
              var alert = $mdDialog.alert({
                title: 'Oops',
                textContent: 'You must enter a name and description.',
                ok: 'Ok'
                });
                      $mdDialog
                .show( alert )
                .finally(function() {
                    $mdDialog.hide();
                });
              return;
          }

          if (!$scope.qVoucher.ratingLevels || $scope.qVoucher.ratingLevels.length==0) {
                var alert = $mdDialog.alert({
                title: 'Oops',
                textContent: 'You must select at least one applicable member rating level.',
                ok: 'Ok'
                });
                      $mdDialog
                .show( alert )
                .finally(function() {
                    $mdDialog.hide();
                });
                
                return;      
          }
          
          $scope.busy = true;

          let backendBaseURI = 'https://89vt1id6w2.execute-api.ap-southeast-2.amazonaws.com/appApi/v1/vouchers';
          let project = projectService.current.project.tag;

          let timezoneOffset = new Date().getTimezoneOffset();
          $scope.qVoucher.timezoneOffset = timezoneOffset;
          
          if (!$scope.qVoucher.id) {
              $scope.qVoucher.createdAt = new Date();
          }
          
          let payload = {"voucher":$scope.qVoucher, "project":project};
          console.log(payload);
          
          $http.post(backendBaseURI, payload).then(function(result) {

            console.log(result);
            $scope.busy = false;
            $scope.qVoucher = result.data.data.voucher;
              
          }, function(result) {

              $scope.busy = false;
              var alert = $mdDialog.alert({
                title: 'Oops',
                textContent: 'We were unable to save this voucher. Please try again.',
                ok: 'Ok'
                });

            $mdDialog
                .show( alert )
                .finally(function() {
                    $mdDialog.hide();
                });
          });
    }

});


angular.module('content')
.directive('onReadFile', function ($parse) {
    return {
        restrict: 'A',
        scope: false,
        link: function(scope, element, attrs) {
            var fn = $parse(attrs.onReadFile);
            
            element.on('click', function (onClickEvent) {
                onClickEvent.target.value = null;
            });

            element.on('change', function(onChangeEvent) {
                var reader = new FileReader();
                
                reader.onload = function(onLoadEvent) {
                    scope.$apply(function() {
                        fn(scope, {$fileContent:onLoadEvent.target.result});
                    });
                };

                reader.readAsText((onChangeEvent.srcElement || onChangeEvent.target).files[0]);

                // allows selection of same file
                onChangeEvent.target.value = null;

            });
        }
    };
});


